<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.33333 15.8333H3.91667C3.13417 15.8333 2.5 15.17 2.5 14.3517V3.98167C2.5 3.16333 3.13417 2.5 3.91667 2.5H15.25C16.0325 2.5 16.6667 3.16333 16.6667 3.98167V7.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.5 5.83329H12.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.8335 9.16667H12.5002" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.8335 12.5H8.3335"  stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.4107 17.2558L18.089 13.5775C18.4148 13.2517 18.4148 12.7242 18.089 12.3992L16.7673 11.0775C16.4415 10.7517 15.914 10.7517 15.589 11.0775L11.9107 14.7558C11.754 14.9125 11.6665 15.1242 11.6665 15.345V17.5H13.8215C14.0423 17.5 14.254 17.4125 14.4107 17.2558V17.2558Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
  name: 'PAGE_CONTENT_CHANGED',
};
</script>

<style scoped>

</style>
