<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect class="bg" x="0.5" y="0.5" width="23" height="23" rx="7.5" />
    <path
      class="ico"
      d="M17.3334 8.3335L10.0001 15.6668L6.66675 12.3335"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect class="border" x="0.5" y="0.5" width="23" height="23" rx="7.5" />
  </svg>
</template>

<script>
export default {
  name: 'btnApply',
};
</script>

<style scoped></style>
