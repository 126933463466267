import { render, staticRenderFns } from "./CopyIcon.vue?vue&type=template&id=3109f86e&scoped=true&"
import script from "./CopyIcon.vue?vue&type=script&lang=js&"
export * from "./CopyIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3109f86e",
  null
  
)

export default component.exports