<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8333 17.5H4.16667C3.24583 17.5 2.5 16.7542 2.5 15.8333V4.16667C2.5 3.24583 3.24583 2.5 4.16667 2.5H15.8333C16.7542 2.5 17.5 3.24583 17.5 4.16667V15.8333C17.5 16.7542 16.7542 17.5 15.8333 17.5Z"
      stroke="#4075A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.167 6.66667H9.16699"
      stroke="#4075A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.16699 9.99992H14.167"
      stroke="#4075A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.16699 13.3334H14.167"
      stroke="#4075A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.832 6.45833C5.717 6.45833 5.62367 6.55167 5.62534 6.66667C5.62534 6.78167 5.71867 6.875 5.83367 6.875C5.94867 6.875 6.042 6.78167 6.042 6.66667C6.042 6.55167 5.94867 6.45833 5.832 6.45833"
      stroke="#4075A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.832 9.79159C5.717 9.79159 5.62367 9.88492 5.62534 9.99992C5.62534 10.1149 5.71867 10.2083 5.83367 10.2083C5.94867 10.2083 6.042 10.1149 6.042 9.99992C6.042 9.88492 5.94867 9.79159 5.832 9.79159"
      stroke="#4075A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.832 13.1251C5.717 13.1251 5.62367 13.2184 5.62534 13.3334C5.62534 13.4484 5.71867 13.5417 5.83367 13.5417C5.94867 13.5417 6.042 13.4484 6.042 13.3334C6.042 13.2184 5.94867 13.1251 5.832 13.1251"
      stroke="#4075A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'profilerKeywords',
};
</script>

<style scoped></style>
