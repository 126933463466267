<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="6" fill="#8F20EF" />
    <path
      d="M15 10H5"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect width="20" height="20" rx="6" stroke="#E5E8EF" />
  </svg>
</template>

<script>
export default {
  name: 'checkboxMinus.vue',
};
</script>

<style scoped></style>
