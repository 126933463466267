<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0949 5.69849C15.1373 7.74093 15.1373 11.0524 13.0949 13.0948C11.0524 15.1373 7.74096 15.1373 5.69852 13.0948C3.65608 11.0524 3.65608 7.74093 5.69852 5.69849C7.74096 3.65605 11.0524 3.65605 13.0949 5.69849"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.8333 15.8333L13.0917 13.0917"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'searchInputIcon',
};
</script>

<style scoped></style>
