<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.86243 12.5L14.0291 16.6667C14.7191 17.3567 15.8391 17.3567 16.5291 16.6667V16.6667C17.2191 15.9767 17.2191 14.8567 16.5291 14.1667L12.3624 10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.69995 5.83337L8.61662 8.75004"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.44414 3.57755L3.00581 5.26338C3.11914 5.60421 3.43747 5.83338 3.79664 5.83338H5.69581V3.93421C5.69581 3.57588 5.46664 3.25671 5.12581 3.14338L3.43997 2.58171C3.28997 2.53171 3.12497 2.57088 3.01331 2.68255L2.54497 3.15088C2.43331 3.26255 2.39414 3.42755 2.44414 3.57755V3.57755Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.4299 4.87252C17.4499 4.89169 17.4649 4.91669 17.4724 4.94336C17.8641 6.24919 17.5608 7.71836 16.5291 8.75002C15.4866 9.79252 13.9974 10.0909 12.6816 9.68086L5.72576 16.6367C5.04826 17.3142 3.94326 17.365 3.24243 16.7117C2.50576 16.0259 2.49076 14.8725 3.19576 14.1667L10.1816 7.18086C9.77159 5.86502 10.0699 4.37586 11.1124 3.33336C12.1441 2.30169 13.6133 1.99836 14.9191 2.39002C14.9458 2.39836 14.9708 2.41252 14.9899 2.43252L15.1249 2.56752C15.1899 2.63252 15.1899 2.73836 15.1249 2.80336L12.9291 5.00002L14.8624 6.93336L17.0591 4.73669C17.1241 4.67169 17.2299 4.67169 17.2949 4.73669L17.4299 4.87252V4.87252Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'menuTools',
};
</script>

<style scoped></style>
