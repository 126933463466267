import { render, staticRenderFns } from "./profilerPosition.vue?vue&type=template&id=dc80e002&scoped=true&"
import script from "./profilerPosition.vue?vue&type=script&lang=js&"
export * from "./profilerPosition.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc80e002",
  null
  
)

export default component.exports