<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="8" fill="#F1FCF8"/>
    <path d="M19.3033 11.0301C20.4541 9.87928 22.3191 9.87928 23.47 11.0301V11.0301C24.6208 12.181 24.6208 14.046 23.47 15.1968L18.5708 20.096C17.42 21.2468 15.555 21.2468 14.4041 20.096V20.096C13.2533 18.9451 13.2533 17.0801 14.4041 15.9293L15.1366 15.1968" stroke="#24C182" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.6967 21.803C11.5458 22.9538 9.68083 22.9538 8.53 21.803V21.803C7.37916 20.6521 7.37916 18.7871 8.53 17.6363L13.4292 12.7371C14.58 11.5863 16.445 11.5863 17.5958 12.7371V12.7371C18.7467 13.888 18.7467 15.753 17.5958 16.9038L16.8333 17.6663" stroke="#24C182" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'HeatmapURL',
};
</script>
