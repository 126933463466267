import { render, staticRenderFns } from "./licenseLogo.vue?vue&type=template&id=57ed192c&scoped=true&"
import script from "./licenseLogo.vue?vue&type=script&lang=js&"
export * from "./licenseLogo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57ed192c",
  null
  
)

export default component.exports