import Vue from 'vue';
import VueRouter from 'vue-router';

function coockieCheck() {
  let cookies = document.cookie.split(';');
  cookies = cookies.map((el) => {
    const index = el.indexOf('=');
    return index > -1 ? el.substring(0, index).trim() : el.trim();
  });
  return cookies.includes('csrftoken') || window.location.origin === 'http://localhost:8080';
}

Vue.use(VueRouter);

const routes = [
  {
    name: 'Insights',
    path: '/',
    component: () => import(/* webpackChunkName: "insights" */ '@/views/Insights.vue'),
    meta: {layout: 'MainLayout'},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "insights" */ '@/views/Insights.vue'),
    meta: {layout: 'MainLayout'},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/finance',
    name: 'Finance',
    component: () => import(/* webpackChunkName: "insights" */ '@/views/Insights.vue'),
    meta: {layout: 'MainLayout'},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/management',
    name: 'Management',
    component: () => import(/* webpackChunkName: "management" */ '@/views/Management/index.vue'),
    meta: {layout: 'MainLayout', path: ['Management'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
    redirect: '/management/pl',
    children: [
      {
        path: '/management/pl',
        component: () => import(/* webpackChunkName: "pl" */ '@/views/Management/PL.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Management',
        meta: {layout: 'MainLayout', path: ['PnL'], requiresChunkTimestamp: true},
      },
      {
        path: '/management/unit-economic',
        component: () => import( /* webpackChunkName: "unitEconomics" */ '@/views/Management/UnitEconomics.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Management',
        meta: {layout: 'MainLayout', path: ['Unit Economics'], requiresChunkTimestamp: true},
      },
      {
        path: '/management/RevenueCabs',
        component: () => import(/* webpackChunkName: "pl" */ '@/views/Management/RevenueCabs.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Management',
        meta: {layout: 'MainLayout', path: ['Revenue Cabs'], requiresChunkTimestamp: true},
      },
    ],
  },
  {
    path: '/link-building',
    name: 'Link Building',
    component: () => import(/* webpackChunkName: "linkBuilding" */ '@/views/LinkBuilding/index.vue'),
    meta: {layout: 'MainLayout', path: ['Link Building']},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
    redirect: '/link-building/link-building-item',
    children: [
      {
        path: '/link-building/link-building-item',
        component: () => import(/* webpackChunkName: "linkBuildingItem" */ '@/views/LinkBuilding/LinkbuildingItem.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Link Building',
        meta: {layout: 'MainLayout', path: ['Link Building Item'], requiresChunkTimestamp: true},
      },
      {
        path: '/reports',
        component: () => import(/* webpackChunkName: "reports" */ '@/views/Reports.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Reports',
        meta: {layout: 'MainLayout', path: ['Reports'], requiresChunkTimestamp: true},
      },
      {
        path: '/link-building/accounts',
        component: () => import( /* webpackChunkName: "accounts" */ '@/views/LinkBuilding/Accounts.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Link Building',
        meta: {layout: 'MainLayout', path: ['Accounts'], requiresChunkTimestamp: true},
      },
      {
        path: '/link-building/outreach-base',
        component: () => import(/* webpackChunkName: "outreachBase" */ '@/views/LinkBuilding/OutreachBase.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Link Building',
        meta: {layout: 'MainLayout', path: ['Outreach Base '], requiresChunkTimestamp: true},
      },
    ],
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "orders" */ '@/views/Orders/index.vue'),
    meta: {layout: 'MainLayout', path: ['OrderItems'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
    redirect: '/orders/orderitems',
    children: [
      {
        path: '/orders/orderitems',
        component: () => import(/* webpackChunkName: "orderItems" */ '@/views/Orders/OrderItems.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Orders',
        meta: {layout: 'MainLayout', path: ['Order Items'], requiresChunkTimestamp: true},
      },
      {
        path: '/orders/suppliers',
        component: () => import(/* webpackChunkName: "suppliers" */ '@/views/Orders/Suppliers.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Orders',
        meta: {layout: 'MainLayout', path: ['Suppliers'], requiresChunkTimestamp: true},
      },
    ],
  },
  {
    path: '/contentmarketing',
    name: 'Content Marketing',
    component: () => import(/* webpackChunkName: "contentMarketing" */ '@/views/ContentMarketing/index.vue'),
    meta: {layout: 'MainLayout', path: ['Content Items'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
    redirect: '/contentmarketing/contentitems',
    children: [
      {
        path: '/contentmarketing/contentitems',
        component: () => import(/* webpackChunkName: "contentItems" */ '@/views/ContentMarketing/ContentItems.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Content Marketing',
        meta: {layout: 'MainLayout', path: ['Content Items'], requiresChunkTimestamp: true},
      },
      {
        path: '/contentmarketing/copywriters',
        component: () => import(/* webpackChunkName: "copywriters" */ '@/views/ContentMarketing/Copywriters.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Content Marketing',
        meta: {layout: 'MainLayout', path: ['Copywriters'], requiresChunkTimestamp: true},
      },
    ],
  },
  {
    path: '/marketing-plan',
    name: 'Marketing Plan',
    component: () => import(/* webpackChunkName: "marketingPlan" */ '@/views/MarketingPlan/index.vue'),
    meta: {layout: 'MainLayout', path: ['Marketing Plan'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
    redirect: '/marketing-plan/content-plan',
    children: [
      {
        path: '/marketing-plan/content-plan',
        component: () => import(/* webpackChunkName: "contentPlan" */ '@/views/MarketingPlan/ContentPlan.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Marketing Plan',
        meta: {layout: 'MainLayout', path: ['Content Plan'], requiresChunkTimestamp: true},
      },
      {
        path: '/marketing-plan/link-building-plan',
        component: () => import(/* webpackChunkName: "linkBuildingPlan" */ '@/views/MarketingPlan/LinkBuildingPlan.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Marketing Plan',
        meta: {layout: 'MainLayout', path: ['Link Building Plan'], requiresChunkTimestamp: true},
      },
      {
        path: '/marketing-plan/pbn-plan',
        component: () => import(/* webpackChunkName: "pbnPlan" */ '@/views/MarketingPlan/PBNPlan'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Marketing Plan',
        meta: {layout: 'MainLayout', path: ['PBN Plan'], requiresChunkTimestamp: true},
      },
    ],
  },
  {
    path: '/affiliate-marketing',
    name: 'Affiliate Marketing',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AffiliateMarketing/index.vue'),
    meta: {layout: 'MainLayout', path: ['Affiliate Marketing']},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
    redirect: '/affiliate-marketing/brand-board',
    children: [
      {
        path: '/affiliate-marketing/brand-board',
        component: () => import(/* webpackChunkName: "dashboards" */ '@/views/AffiliateMarketing/BrandBoard.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Brand Board',
        meta: {layout: 'MainLayout', path: ['Brand Board']},
      },
    ],
  },
  {
    path: '/monetization/byproject',
    component: () => import(/* webpackChunkName: "allStats" */ '@/views/AllStats.vue'),
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
    name: 'Monetization',
    meta: {layout: 'MainLayout', path: ['By Project'], requiresChunkTimestamp: true},
  },
  {
    path: '/monetization/bybrand',
    component: () => import(/* webpackChunkName: "allStatsBrand" */ '@/views/AllStatsBrand.vue'),
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
    name: 'Monetization',
    meta: {layout: 'MainLayout', path: ['By Brand'], requiresChunkTimestamp: true},
  },
  {
    path: '/finance/tasks',
    component: () => import(/* webpackChunkName: "financeTasks" */ '@/views/Finance/FinanceTasks.vue'),
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
    name: 'Finance',
    meta: {layout: 'MainLayout', path: ['Tasks'], requiresChunkTimestamp: true},
  },
  {
    path: '/finance/balance',
    component: () => import(/* webpackChunkName: "balance" */ '@/views/Finance/Balance.vue'),
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
    name: 'Finance',
    meta: {layout: 'MainLayout', path: ['Balance'], requiresChunkTimestamp: true},
  },
  {
    path: '/semantics',
    name: 'Semantics',
    component: () => import(/* webpackChunkName: "semantics" */ '@/views/Semantics.vue'),
    meta: {layout: 'MainLayout', path: ['Core'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/finance/my-cards',
    component: () => import(/* webpackChunkName: "myCards" */ '@/views/Finance/MyCards.vue'),
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
    name: 'Finance',
    meta: {layout: 'MainLayout', path: ['My Cards'], requiresChunkTimestamp: true},
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import(/* webpackChunkName: "maintenance" */ '@/views/Maintenance/index.vue'),
    meta: {layout: 'MainLayout', path: ['Maintenance'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
    redirect: '/maintenance/projects',
    children: [
      {
        path: '/maintenance/tools',
        component: () => import( /* webpackChunkName: "tools" */ '@/views/Maintenance/Tools.vue' ),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Maintenance',
        meta: {layout: 'MainLayout', path: ['Tools'], requiresChunkTimestamp: true},
      },
      {
        path: '/maintenance/projects',
        component: () => import(/* webpackChunkName: "projects" */ '@/views/Maintenance/Projects.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Maintenance',
        meta: {layout: 'MainLayout', path: ['Projects'], requiresChunkTimestamp: true},
      },

      {
        path: '/maintenance/gsc-connections',
        component: () => import(/* webpackChunkName: "gscConnections" */ '@/views/Maintenance/GSCConnections.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Maintenance',
        meta: {layout: 'MainLayout', path: ['Google Connections'], requiresChunkTimestamp: true},
      },
      {
        path: '/maintenance/emails',
        component: () => import(/* webpackChunkName: "emails" */ '@/views/Maintenance/Emails.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Maintenance',
        meta: {layout: 'MainLayout', path: ['Emails'], requiresChunkTimestamp: true},
      },
      {
        path: '/maintenance/hostings-registrators',
        component: () => import(/* webpackChunkName: "hostingsRegistrators" */ '@/views/Maintenance/HostingsRegistrators.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Maintenance',
        meta: {layout: 'MainLayout', path: ['Hostings & Registrators'], requiresChunkTimestamp: true},
      },
      {
        path: '/maintenance/project-management',
        component: () => import(/* webpackChunkName: "projectManagment" */ '@/views/Maintenance/ProjectManagment.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Maintenance',
        meta: {layout: 'MainLayout', path: ['Project Managment'], requiresChunkTimestamp: true},
      },
    ],
  },
  {
    path: '/overview',
    name: 'Tools',
    component: () => import(/* webpackChunkName: "overview" */ '@/views/Overview.vue'),
    meta: {layout: 'MainLayout', path: ['Project', 'Overview'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/brief',
    component: () => import(/* webpackChunkName: "brief" */ '@/views/Brief/index.vue'),
    name: 'Tools',
    meta: {layout: 'MainLayout', path: ['Project', 'Brief Analytics'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
    redirect: '/brief/analytics',
    children: [
      {
        path: '/brief/analytics',
        component: () => import(/* webpackChunkName: "analytics" */ '@/views/Brief/Analytics.vue'),
        beforeEnter: function(to, prev, next) {
          if (coockieCheck()) next();
          else next('/login');
        },
        name: 'Tools',
        meta: {layout: 'MainLayout', path: ['Project', 'Brief Analytics'], requiresChunkTimestamp: true},
      },
      {
        path: '/brief/traffic',
        component: () => import(/* webpackChunkName: "traffic" */ '@/views/Brief/Traffic.vue'),
        name: 'Tools',
        meta: {
          layout: 'MainLayout',
          path: ['Project', 'Brief Analytics', 'Traffic'],
          requiresChunkTimestamp: true,
        },
      },
      {
        path: '/brief/keyword-description',
        component: () => import(/* webpackChunkName: "keywordDescription" */ '@/views/Brief/KeywordDescription.vue'),
        name: 'Tools',
        meta: {
          layout: 'MainLayout',
          path: ['Project', 'Brief Analytics', 'Keyword Description'],
          requiresChunkTimestamp: true,
        },
      },
      {
        path: '/brief/ref-domains',
        component: () => import(/* webpackChunkName: "refDomains" */ '@/views/Brief/RefDomains.vue'),
        name: 'Tools',
        meta: {
          layout: 'MainLayout',
          path: ['Project', 'Brief Analytics', 'Ref Domains'],
          requiresChunkTimestamp: true,
        },
      },
      {
        path: '/brief/content',
        component: () => import(/* webpackChunkName: "content" */ '@/views/Brief/Content.vue'),
        name: 'Tools',
        meta: {
          layout: 'MainLayout',
          path: ['Project', 'Brief Analytics', 'Content'],
          requiresChunkTimestamp: true,
        },
      },
      {
        path: '/brief/costs',
        component: () => import(/* webpackChunkName: "costs" */ '@/views/Brief/Costs.vue'),
        name: 'Tools',
        meta: {
          layout: 'MainLayout',
          path: ['Project', 'Brief Analytics', 'Costs'],
          requiresChunkTimestamp: true,
        },
      },
      {
        path: '/brief/all-info',
        component: () => import(/* webpackChunkName: "allInfo" */ '@/views/Brief/AllInfo.vue'),
        name: 'Tools',
        meta: {
          layout: 'MainLayout',
          path: ['Project', 'Brief Analytics', 'All Info'],
          requiresChunkTimestamp: true,
        },
      },
    ],
  },
  {
    path: '/profiler',
    name: 'Tools',
    component: () => import(/* webpackChunkName: "profiler" */ '@/views/Profiler.vue'),
    meta: {layout: 'MainLayout', path: ['Project', 'Profiler'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/spam-checker',
    name: 'Tools',
    component: () => import(/* webpackChunkName: "spamChecker" */ '@/views/SpamChecker.vue'),
    meta: {layout: 'MainLayout', path: ['Backlinks', 'Spam Checker'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/reuse-checker',
    name: 'Tools',
    component: () => import(/* webpackChunkName: "reuseChecker" */ '@/views/ReuseChecker.vue'),
    meta: {layout: 'MainLayout', path: ['Backlinks', 'Reuse Checker'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/pbn',
    name: 'Tools',
    component: () => import(/* webpackChunkName: "pbn" */ '@/views/PBN.vue'),
    meta: {layout: 'MainLayout', path: ['Backlinks', 'Link PBN'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/anchor-analyzer',
    name: 'Tools',
    component: () => import(/* webpackChunkName: "anchorAnalyzer" */ '@/views/AnchorAnalyzer.vue'),
    meta: {layout: 'MainLayout', path: ['Backlinks', 'Anchor Analyzer'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/heatmap',
    name: 'Heatmap',
    component: () => import(/* webpackChunkName: "heatmap" */ '@/views/Heatmap.vue'),
    meta: {layout: 'MainLayout', path: ['Backlinks', 'Heatmap'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/organic-search/keywords-checker',
    name: 'Keywords',
    component: () => import(/* webpackChunkName: "keywordsChecker" */ '@/views/KeywordsChecker.vue'),
    meta: {
      layout: 'MainLayout',
      path: ['Organic Search', 'Keywords Checker'],
      requiresChunkTimestamp: true,
    },
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/organic-search/top-pages',
    name: 'Top Pages',
    component: () => import(/* webpackChunkName: "topPages" */ '@/views/TopPages.vue'),
    meta: {layout: 'MainLayout', path: ['Organic Search', 'Top Pages'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/organic-search/content-gap',
    name: 'Content Gap',
    component: () => import(/* webpackChunkName: "contentGap" */ '@/views/ContentGap.vue'),
    meta: {layout: 'MainLayout', path: ['Organic Search', 'Content Gap'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/organic-search/crossmap',
    name: 'Crossmap',
    component: () => import(/* webpackChunkName: "crossmap" */ '@/views/Crossmap.vue'),
    meta: {layout: 'MainLayout', path: ['Organic Search', 'Crossmap'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/development/logs',
    name: 'Development',
    component: () => import(/* webpackChunkName: "logs" */ '@/views/Development/Logs.vue'),
    meta: {layout: 'MainLayout', path: ['Logs'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/development/balance-dashboard',
    name: 'Development',
    component: () => import(/* webpackChunkName: "balanceDashboard" */ '@/views/Development/BalanceDashboard'),
    meta: {layout: 'MainLayout', path: ['Balance Dashboard'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/development/feature-flags',
    name: 'Development',
    component: () => import(/* webpackChunkName: "featureFlags" */ '@/views/Development/FeatureFlags'),
    meta: {layout: 'MainLayout', path: ['Feature Flags'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/pbn-fake',
    name: 'PBNFake',
    component: () =>
      import(/* webpackChunkName: "pbnFake" */ '@/views/PBNFake.vue'),
    meta: {layout: 'MainLayout', path: ['PBN'], requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import(/* webpackChunkName: "team" */ '@/views/Team.vue'),
    meta: {layout: 'MainLayout', requiresChunkTimestamp: true},
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {layout: 'EmptyLayout', requiresChunkTimestamp: true},
  },
  {
    path: '/rejected',
    name: 'Rejected',
    meta: {layout: 'EmptyLayout', requiresChunkTimestamp: true},
    component: () => import(/* webpackChunkName: "rejected" */ '../views/Rejected.vue'),
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
  },
  {
    path: '/contentmarketing/contentitems/stepper',
    redirect: '/contentmarketing/contentitems',
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/contentmarketing/copywriters/stepper',
    redirect: '/contentmarketing/copywriters',
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/management/pl/stepper',
    redirect: '/management/pl',
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/management/unit-economic/stepper',
    redirect: '/management/unit-economic',
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/link-building/link-building-item/stepper',
    redirect: '/link-building/link-building-item',
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/orders/suppliers/stepper',
    redirect: '/orders/suppliers',
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/monetization/byproject/stepper',
    redirect: '/monetization/byproject',
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/monetization/bybrand/stepper',
    redirect: '/monetization/bybrand',
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/organic-search/crossmap/stepper',
    redirect: '/organic-search/crossmap/',
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
  {
    path: '/semantics/stepper',
    redirect: '/semantics',
    beforeEnter: function(to, prev, next) {
      if (coockieCheck()) next();
      else next('/login');
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  base: '',
});


router.onError((error) => {
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    window.location.reload();
  }
  else if (/Loading.*chunk.*failed./i.test(error.message)) {
    window.location.reload();
  }
});

router.beforeEach((to, from, next) => {
  // 1. Генерируем уникальный временной параметр в миллисекундах для предотвращения кэширования
  const timestamp = new Date().getTime();
  // 2. Проверяем, требуется ли добавлять временной параметр к этому маршруту
  if (to.matched.some((record) => record.meta.requiresChunkTimestamp)) {
    // 3. Проверяем, существует ли уже параметр timestamp в URL-адресе
    if (!to.query.timestamp) {
      // 3.a. Если параметра нет, добавляем его с текущим временем
      to.query.timestamp = timestamp;
    } else {
      // 3.b. Если параметр есть, обновляем его только при необходимости
      if (timestamp > parseInt(to.query.timestamp)) {
        to.query.timestamp = timestamp;
      }
    }
  }
  // 4. Переходим к следующему маршруту с потенциально обновленным URL-адресом
  next();
});

export default router;


