<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.125 14.125L5.875 5.875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.125 8.23083V14.125L8.23169 14.1242"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'moveDown',
};
</script>

<style scoped></style>
