import { render, staticRenderFns } from "./Fiverr.vue?vue&type=template&id=38d8cecd&scoped=true&"
import script from "./Fiverr.vue?vue&type=script&lang=js&"
export * from "./Fiverr.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d8cecd",
  null
  
)

export default component.exports