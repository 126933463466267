<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.7525 18.3368C11.6808 18.3368 10.001 16.6569 10.001 14.5852C10.001 12.5135 11.6808 10.8337 13.7525 10.8337C15.8251 10.8337 17.5041 12.5135 17.5041 14.5852C17.5041 16.6569 15.8251 18.3368 13.7525 18.3368" stroke="#FC5A5A" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.8102 13.5273L12.6943 15.6432" stroke="#FC5A5A" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.8102 15.6432L12.6943 13.5273" stroke="#FC5A5A" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.0026 8.33265V6.5219C15.0026 6.08005 14.8267 5.6557 14.514 5.34307L12.1564 2.98542C11.8438 2.67279 11.4194 2.49689 10.9776 2.49689H4.99842C4.0772 2.49689 3.33105 3.24303 3.33105 4.16425V15.8358C3.33105 16.757 4.0772 17.5031 4.99842 17.5031H8.33314" stroke="#FC5A5A" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.0024 6.66529H11.6677C11.2075 6.66529 10.834 6.2918 10.834 5.83161V2.49689" stroke="#FC5A5A" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>
<script>
export default {
  name: 'deleteDocument',
};
</script>
<style scoped></style>
