<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M9 15.75V15.75C5.27175 15.75 2.25 12.7283 2.25 9V9C2.25 5.27175 5.27175 2.25 9 2.25V2.25C12.7283 2.25 15.75 5.27175 15.75 9V9C15.75 12.7283 12.7283 15.75 9 15.75Z"
          stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 7.5L8.25 11.25L6 9" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'check',
};
</script>

<style scoped>

</style>
