<template>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="8" fill="#F9F4FE"/>
<path d="M17.1941 8.55591C20.74 9.15174 23.4441 12.2284 23.4441 15.9434C23.4441 20.0859 20.0866 23.4434 15.9441 23.4434C12.2291 23.4434 9.15247 20.7392 8.55664 17.1934" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.55581 14.6942C8.63498 14.2234 8.75748 13.7692 8.91998 13.3325" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.1641 10.165C10.8 10.4659 10.4658 10.8 10.165 11.1642" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.6941 8.55594C14.2233 8.63511 13.7683 8.75844 13.3325 8.92094" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.3258 11.8335V16.326H12.6666" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: 'LBPtime',
};
</script>

<style scoped>

</style>
