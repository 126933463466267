import { render, staticRenderFns } from "./updatedContent.vue?vue&type=template&id=41a97520&scoped=true&"
import script from "./updatedContent.vue?vue&type=script&lang=js&"
export * from "./updatedContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41a97520",
  null
  
)

export default component.exports