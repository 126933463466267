import { render, staticRenderFns } from "./PAGE_META_CHANGED.vue?vue&type=template&id=026007b0&scoped=true&"
import script from "./PAGE_META_CHANGED.vue?vue&type=script&lang=js&"
export * from "./PAGE_META_CHANGED.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "026007b0",
  null
  
)

export default component.exports