<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
    content-class="box-shadow-12 mt-1 rounded-3"
    style="z-index: 100">
    <template v-slot:activator="{on, attrs}">
      <span v-bind="attrs" v-on="on" :class="{'cursor-unset': !items.length}">
        <span class="text-grey fz-12" :class="{'text-hover-primary pointer': items.length}">{{
          lastCollection ? lastCollection : minLastDate
        }}</span>
        <span
          v-if="items.length && !minLastDate && !lastCollection"
          class="text-grey fz-12"
          :class="{'text-hover-primary pointer': items.length}">
          ⚠️ {{ lastDate }}
        </span>
      </span>
    </template>
    <v-sheet
      width="900"
      max-height="500"
      class="rounded-3 pa-7 scroll"
      style="box-shadow: 0px 8px 24px rgba(42, 53, 90, 0.1)">
      <p v-if="modalLoading" class="fz-14 text-center text-grey">Stat loading...</p>
      <v-data-table
        v-else
        fixed-header
        class="table-small"
        :headers="modalHeadersUrl"
        :items="items"
        hide-default-footer
        :options.sync="modalOptions">
        <template v-slot:header.target="">
          <v-row class="ma-0 align-center flex-nowrap">
            <v-text-field
              @click.stop
              placeholder="Target"
              v-model="searchObj.searchTarget"
              outlined
              hide-details
              style="min-width: 110px; max-width: 150px"
              class="table-header-input d-inline-block ma-0 ml-n3 border-none fz-12"
              dense>
              <template v-slot:prepend-inner="">
                <v-icon v-text="'$searchIcon'" class="stroke-black"></v-icon>
              </template>
            </v-text-field>
            <sort-f-icon :multisort="true" :options="modalOptions" value="target" />
          </v-row>
        </template>
        <template v-slot:header.analyticCollection="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap">
            <span class="fz-12 font-weight-medium text-nowrap">Analytic Collection</span>
            <sort-f-icon :multisort="true" :options="modalOptions" value="analyticCollection" />
          </v-row>
        </template>
        <template v-slot:header.contentLastUpdate="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap sortNone">
            <span class="fz-12 font-weight-medium text-nowrap">Content Last Update</span>
            <sort-f-icon :multisort="true" :options="modalOptions" value="contentLastUpdate" />
          </v-row>
        </template>
        <template v-slot:header.gscMaxDate="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap">
            <span class="fz-12 font-weight-medium text-nowrap">GSC Max Date</span>
            <sort-f-icon :multisort="true" :options="modalOptions" value="gscMaxDate" />
          </v-row>
        </template>
        <template v-slot:header.keywordCollection="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap">
            <span class="fz-12 font-weight-medium text-nowrap">Keyword Collection</span>
            <sort-f-icon :multisort="true" :options="modalOptions" value="keywordCollection" />
          </v-row>
        </template>
        <template v-slot:header.lactCheckIndexDate="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap">
            <span class="fz-12 font-weight-medium text-nowrap">Lact Check Index Date</span>
            <sort-f-icon :multisort="true" :options="modalOptions" value="lactCheckIndexDate" />
          </v-row>
        </template>
        <template v-slot:header.lastUpdate="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap">
            <span class="fz-12 font-weight-medium text-nowrap">Last Update</span>
            <sort-f-icon :multisort="true" :options="modalOptions" value="lastUpdate" />
          </v-row>
        </template>
        <template v-slot:header.pageCollection="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap">
            <span class="fz-12 font-weight-medium text-nowrap">Page Collection</span>
            <sort-f-icon :multisort="true" :options="modalOptions" value="pageCollection" />
          </v-row>
        </template>
        <template v-slot:header.trafficCollection="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap">
            <span class="fz-12 font-weight-medium text-nowrap">Traffic Collection</span>
            <sort-f-icon :multisort="true" :options="modalOptions" value="trafficCollection" />
          </v-row>
        </template>
        <template v-slot:item.target="{item}">
          <span class="text-no-wrap">{{ item.target }}</span>
        </template>
        <template v-slot:item.analyticCollection="{item}">
          <span class="text-no-wrap">{{
            item.analyticCollection ? formatDate(item.analyticCollection, 'MD Time') : '---'
          }}</span>
        </template>
        <template v-slot:item.contentLastUpdate="{item}">
          <span class="text-no-wrap">{{
            item.contentLastUpdate ? formatDate(item.contentLastUpdate, 'MD Time') : '---'
          }}</span>
        </template>
        <template v-slot:item.gscMaxDate="{item}">
          <span class="text-no-wrap">{{
            item.gscMaxDate ? formatDate(item.gscMaxDate, 'MD Time') : '---'
          }}</span>
        </template>
        <template v-slot:item.keywordCollection="{item}">
          <span class="text-no-wrap">{{
            item.keywordCollection ? formatDate(item.keywordCollection, 'MD Time') : '---'
          }}</span>
        </template>
        <template v-slot:item.lactCheckIndexDate="{item}">
          <span class="text-no-wrap">{{
            item.lactCheckIndexDate ? formatDate(item.lactCheckIndexDate, 'MD Time') : '---'
          }}</span>
        </template>
        <template v-slot:item.lastUpdate="{item}">
          <span class="text-no-wrap">{{
            item.lastUpdate ? formatDate(item.lastUpdate, 'MD Time') : '---'
          }}</span>
        </template>
        <template v-slot:item.pageCollection="{item}">
          <span class="text-no-wrap">{{
            item.pageCollection ? formatDate(item.pageCollection, 'MD Time') : '---'
          }}</span>
        </template>
        <template v-slot:item.trafficCollection="{item}">
          <span class="text-no-wrap">{{
            item.trafficCollection ? formatDate(item.trafficCollection, 'MD Time') : '---'
          }}</span>
        </template>
      </v-data-table>
    </v-sheet>
  </v-menu>
</template>

<script>
import formatDate from '@/helpers/formatDate';
import sortFIcon from '@/components/tables/SortFIcon';

export default {
  name: 'AppBarTableStat',
  components: {sortFIcon},
  props: ['items', 'minLastDate', 'lastCollection', 'searchObj'],
  methods: {
    formatDate,
  },
  data() {
    return {
      modalOptions: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 1000,
        multiSort: true,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: [true],
      },
      modalLoading: false,
      modalHeadersUrl: [
        {
          text: 'Target',
          value: 'target',
          filterable: false,
        },
        {
          text: 'Analytic Collection',
          value: 'analyticCollection',
          filterable: false,
        },
        {
          text: 'Content Last Update',
          value: 'contentLastUpdate',
          filterable: false,
        },
        {
          text: 'GSC Max Date',
          value: 'gscMaxDate',
          filterable: false,
        },
        {
          text: 'Keyword Collection',
          value: 'keywordCollection',
          filterable: false,
        },
        {
          text: 'Last Check Index Date',
          value: 'lactCheckIndexDate',
          filterable: false,
        },
        {
          text: 'Last Update',
          value: 'lastUpdate',
          filterable: false,
        },
        {
          text: 'Page Collection',
          value: 'pageCollection',
          filterable: false,
        },
        {
          text: 'Traffic Collection',
          value: 'trafficCollection',
          filterable: false,
        },
      ],
    };
  },
  computed: {
    lastDate() {
      return formatDate(
          this.items.find((item) => item.contentLastUpdate)?.contentLastUpdate || null,
          'MD Time',
      );
    },
  },
};
</script>

<style>
.v-data-table-header__sort-badge {
  display: none !important;
}
</style>
