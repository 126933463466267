import gql from 'graphql-tag';

export default async function frontErrorHandler(error, vm, info, componentName) {
  if (process.env.NODE_ENV !== 'development') {
    await vm.$apollo.mutate({
      mutation: gql`
        mutation ReportBug($input: UserEventLogCreateInput!) {
          technical {
            logging {
              createUserBug(input: $input)
            }
          }
        }`,
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          exception: info && componentName ? `Event: ${info}; ${error}; component: ${componentName}` : error || error.message,
          path: vm.$route ? vm.$route.path : window.location.pathname,
        },
      },
    }).then(() => {
      if (vm.addSnack) {
        vm.addSnack('Error', 'Error', 'Oops! Something goes wrong in app. Message was sent to developers!');
      }
    }).catch(() => {
      console.log('Friendly fire');
    });
  }
}

