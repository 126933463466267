<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6694 7.66499H14.3347C13.8742 7.66499 13.501 7.29173 13.501 6.8313V3.49658"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.99756 7.66498V5.16394C5.99756 4.24309 6.74406 3.49658 7.66492 3.49658H13.6441C14.0863 3.49658 14.5103 3.67241 14.8229 3.98512L17.1806 6.34277C17.4933 6.65539 17.6691 7.07942 17.6691 7.52159V16.8355C17.6691 17.7563 16.9226 18.5028 16.0017 18.5028H7.66492C6.74406 18.5028 5.99756 17.7563 5.99756 16.8355V16.0018"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.97126 13.2924L6.47021 10.3745"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.47021 13.2924L8.97126 10.3745"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="3.49658"
      y="7.66504"
      width="8.33681"
      height="8.33681"
      rx="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'spamDownload',
};
</script>

<style scoped></style>
