<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.5">
<path d="M12.6665 12.653L13.9998 11.3197L12.6665 9.98633" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.788 6.00027L5.73467 4.55227C5.48267 4.2056 5.07933 4.0016 4.65067 4.00294L2 4.01427"  stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.6665 5.33366L13.9998 4.00033L12.6665 2.66699"  stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.21191 9.33398L10.2652 10.782C10.5172 11.1287 10.9206 11.3327 11.3492 11.3313L13.9999 11.32"  stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 4.01428L11.3493 4.00294C10.9207 4.00094 10.5173 4.20561 10.2653 4.55228L5.73467 10.7816C5.48267 11.1283 5.07933 11.3323 4.65067 11.3309L2 11.3196" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>

</template>

<script>
export default {
  name: 'arrowShuffle',
};
</script>

<style scoped>

</style>
