<template>
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 57V36"
      stroke="#C2C9DA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.499 42L36 35.499L42.501 42"
      stroke="#C2C9DA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48.0008 57H55.6808C61.4648 57 66.1808 52.284 66.1808 46.5C66.1808 40.716 61.4648 36 55.6808 36H54.3758V33C54.3758 23.07 46.3058 15 36.3758 15C27.4448 15 20.0408 21.534 18.6368 30.069C11.5058 30.432 5.8208 36.279 5.8208 43.5C5.8208 50.955 11.8658 57 19.3208 57H24.0008"
      stroke="#C2C9DA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'cloud',
};
</script>

<style scoped></style>
