<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.7358 17.2916L11.7358 7.29163L14.7917 7.29163C15.2517 7.29163 15.625 7.66496 15.625 8.12496L15.625 17.2916L11.7358 17.2916Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.84741 17.2916L7.84741 3.12496C7.84741 2.66496 8.22074 2.29163 8.68074 2.29163L10.9032 2.29163C11.3632 2.29163 11.7366 2.66496 11.7366 3.12496L11.7366 17.2916L7.84741 17.2916Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.95825 17.2916L3.95825 5.62496C3.95825 5.16496 4.33158 4.79163 4.79158 4.79163L7.84742 4.79163L7.84742 17.2916L3.95825 17.2916Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.29163 17.2916L17.2916 17.2916"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'menuInsights',
};
</script>

<style scoped></style>
