<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 7.49999H17.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.97498 10.8333H5.83331"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 15.8333H5C3.61917 15.8333 2.5 14.7142 2.5 13.3333V6.66666C2.5 5.28583 3.61917 4.16666 5 4.16666H15C16.3808 4.16666 17.5 5.28583 17.5 6.66666V13.3333C17.5 14.7142 16.3808 15.8333 15 15.8333Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'cardsmall',
};
</script>

<style scoped></style>
