<template>
  <v-col class="pa-0 sidebar d-flex flex-column" style="height: 100%" @click.stop>
    <v-list class="pa-0">
      <v-list-item class="logo ma-0 d-flex pr-5 align-center justify-space-between pl-7" style="height: 76px">
        <v-list-item-icon class="my-auto mr-0">
          <v-icon
            style="stroke: none !important; max-height: 20px"
            size="120"
            v-text="'$logo'"
            :class="{logoFill: !sidebarMini}" />
        </v-list-item-icon>
        <v-list-item-content class="ml-auto" style="max-width: 28px; overflow: unset">
          <v-btn
            width="20"
            height="20"
            max-width="20"
            style="background-color: #f5f7fb"
            class="pa-2 rounded"
            v-if="!sidebarMini && $vuetify.breakpoint.mdAndUp"
            @click="
              $emit('hideSidebar');
              accordeon.accordeonMTShow = null;
            "
            icon>
            <v-icon size="20" class="stroke-grey">$chevronLeft</v-icon>
          </v-btn>
          <v-icon
            v-if="$vuetify.breakpoint.smAndDown"
            @click="
              $emit('hideSidebar');
              accordeon.accordeonMTShow = null;
            ">
            mdi-close
          </v-icon>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list class="ma-0 pa-0">
      <template v-if="!sidebarMini">
        <v-list-item
          v-if="permissionList('readOverview', 'useBrief', 'useToolsProfiler')"
          class="rounded-0 mb-4 pl-7"
          style="min-height: 44px; background-color: #f5f7fb !important">
          <v-list-item-content class="fz-16 text-grey">
            <v-list-item-title v-text="'Project'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permission('readOverview')"
          class="rounded-0 v-list-item--link pl-7"
          dense
          to="/overview"
          style="min-height: 28px; height: 28px"
          :class="{'subpage-title': $route.name === 'Overview'}">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'Overview'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-expansion-panels
          v-if="permission('useBrief')"
          v-model="brefShow"
          accordion
          class="elevation-0 nav-accordeon"
          :readonly="!selectedTool.selectedDomain">
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-0" style="height: 28px; min-height: 28px">
              <v-list-item
                class="rounded-0 v-list-item--link pl-7"
                dense
                to="/brief"
                :class="{
                  'subpage-title': $route.path === '/brief/analytics',
                }"
                style="min-height: 28px; height: 28px"
                @click="brefUpdate">
                <v-list-item-content class="fz-14">
                  <v-list-item-title
                    class="font-weight-regular"
                    v-text="'Brief Analytics'"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <template v-slot:actions>
                <v-icon size="20" class="stroke-grey"> $chevronRight</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list class="ma-0 mb-2 pa-0 expand-list">
                <v-list-item
                  class="rounded-0 mb-0 pl-7"
                  style="height: unset; min-height: 10px; background-color: transparent !important"
                  v-for="(link, index) in brefList"
                  :key="index + 'subpage' + link.name"
                  :to="link.url"
                  :class="{'subpage-title': !link.url}">
                  <v-list-item-content class="py-0 ml-9" :class="{'fz-14': link.url}">
                    {{ link.name }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  class="rounded-0 mb-0 pl-7"
                  to="/brief/all-info"
                  style="height: unset; min-height: 10px; background-color: transparent !important"
                  v-if="selectedTool && selectedTool.charts && selectedTool.charts.tableDataFrame">
                  <v-list-item-content class="py-0 ml-9 fz-14">
                    {{ 'All info' }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-list-item
          v-if="permission('useToolsProfiler')"
          class="rounded-0 v-list-item--link pl-7 mb-3"
          dense
          to="/profiler"
          style="min-height: 28px; height: 28px"
          :class="{'subpage-title': $route.name === 'Profiler'}">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'Profiler'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permission('useToolsMonetization')"
          class="rounded-0 pl-7"
          style="min-height: 44px; background-color: #f5f7fb !important">
          <v-list-item-content class="fz-16 text-grey">
            <v-list-item-title v-text="'Monetization'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permission('useToolsMonetization')"
          class="rounded-0 v-list-item--link mt-2 pl-7"
          dense
          to="/monetization/byproject"
          style="min-height: 28px; height: 28px"
          :class="{'subpage-title': $route.name === 'By Project'}">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'By Project'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permission('useToolsMonetization')"
          class="rounded-0 v-list-item--link mb-2 pl-7"
          dense
          to="/monetization/bybrand"
          style="min-height: 28px; height: 28px"
          :class="{'subpage-title': $route.name === 'By Brand'}">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'By Brand'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permissionList('useToolsSpamChecker', 'useToolsReuseChecker', 'readLinkPbn')"
          class="rounded-0 mb-3 pl-7"
          style="min-height: 44px; background-color: #f5f7fb !important">
          <v-list-item-content class="fz-16 text-grey">
            <v-list-item-title class="font-weight-regular" v-text="'Backlinks'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permission('useToolsSpamChecker')"
          class="rounded-0 v-list-item--link pl-7 mb-0"
          dense
          to="/spam-checker"
          style="min-height: 28px; height: 28px">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'Spam Checker'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permission('useToolsReuseChecker')"
          class="rounded-0 v-list-item--link pl-7 mb-0"
          dense
          to="/reuse-checker"
          style="min-height: 28px; height: 28px"
          :class="{'v-list-item--active': $route.name === 'Reuse Checker'}">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'Reuse Checker'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permission('readLinkPbn')"
          class="rounded-0 v-list-item--link pl-7"
          dense
          to="/pbn"
          style="min-height: 28px; height: 28px"
          :class="{'v-list-item--active': $route.name === 'Link PBN'}">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'Link PBN'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permission('readToolsAnchorAnalyzer')"
          class="rounded-0 v-list-item--link pl-7"
          dense
          to="/anchor-analyzer"
          style="min-height: 28px; height: 28px"
          :class="{'v-list-item--active': $route.name === 'Anchor Analyzer'}">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'Anchor Analyzer'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permission('readToolsHeatmap')"
          class="rounded-0 v-list-item--link pl-7 mb-3"
          dense
          to="/heatmap"
          style="min-height: 28px; height: 28px"
          :class="{'v-list-item--active': $route.name === 'Heatmap'}">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'Heatmap'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permission('readTechnical')"
          class="rounded-0 pl-7"
          style="min-height: 44px; background-color: #f5f7fb !important">
          <v-list-item-content class="fz-16 text-grey">
            <v-list-item-title class="font-weight-regular" v-text="'Development'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permission('readTechnical')"
          class="rounded-0 v-list-item--link pl-7 my-2"
          dense
          to="/development/logs"
          style="min-height: 28px; height: 28px">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'Logs'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permission('readSourceGraphic')"
          class="rounded-0 v-list-item--link pl-7 my-2"
          dense
          to="/development/balance-dashboard"
          style="min-height: 28px; height: 28px">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'Balance Dashboard'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permission('readTechnical')"
          class="rounded-0 v-list-item--link pl-7 my-2"
          dense
          to="/development/feature-flags"
          style="min-height: 28px; height: 28px">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'Feature Flags'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="
            permissionList(
              'useToolsKeywordsChecker',
              'useToolsTopPages',
              'readToolsContentGap',
              'useToolsCrossmap'
            )
          "
          class="rounded-0 mb-3 pl-7"
          style="min-height: 44px; background-color: #f5f7fb !important">
          <v-list-item-content class="fz-16 text-grey">
            <v-list-item-title class="font-weight-regular" v-text="'Organic Search'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permission('useToolsKeywordsChecker')"
          class="rounded-0 v-list-item--link pl-7"
          dense
          to="/organic-search/keywords-checker"
          style="min-height: 28px; height: 28px"
          :class="{
            'v-list-item--active': $route.name === 'Keywords Checker',
          }">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'Keywords Checker'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permission('useToolsTopPages')"
          class="rounded-0 v-list-item--link pl-7"
          dense
          to="/organic-search/top-pages"
          style="min-height: 28px; height: 28px"
          :class="{
            'v-list-item--active': $route.name === 'Top Pages',
          }">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'Top Pages'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permissionList('readToolsContentGap', 'writeToolsContentGap')"
          class="rounded-0 v-list-item--link pl-7"
          dense
          to="/organic-search/content-gap"
          style="min-height: 28px; height: 28px"
          :class="{
            'v-list-item--active': $route.name === 'Content Gap',
          }">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'Content Gap'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="permissionList('useToolsCrossmap')"
          class="rounded-0 v-list-item--link pl-7 mb-3"
          dense
          to="/organic-search/crossmap"
          style="min-height: 28px; height: 28px"
          :class="{
            'v-list-item--active': $route.name === 'Crossmap',
          }">
          <v-list-item-content class="fz-14">
            <v-list-item-title class="font-weight-regular" v-text="'Crossmap'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider style="border-color: #e5e8ef" class="mb-3"></v-divider>
      </template>
      <v-list-item
        v-if="permission('useInsights')"
        class="rounded-0 mb-0 pl-7"
        dense
        to="/"
        style="min-height: 44px; height: 44px">
        <v-list-item-icon class="stroke-grey mr-3 my-auto">
          <v-icon size="20" class="my-auto">$menuInsights</v-icon>
        </v-list-item-icon>

        <v-list-item-content class="fz-14">
          <v-list-item-title class="font-weight-regular"> Insights </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-expansion-panels
        v-model="accordeon.accordeonFNShow"
        v-if="permission('readFinance')"
        accordion
        class="elevation-0 nav-accordeon">
        <v-expansion-panel v-for="(item, i) in filterByPermissions(accordeonFN)" :key="i">
          <v-expansion-panel-header
            class="pa-0"
            style="height: 44px; min-height: 44px"
            @click="$emit('showSidebar')">
            <v-list-item
              class="rounded-0 v-list-item--link pl-7"
              dense
              style="height: 44px; min-height: 44px"
              :class="{'v-list-item--active': item.name === $route.name}">
              <v-list-item-icon class="stroke-grey mr-3 my-auto">
                <v-icon size="20" class="my-auto" v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content class="fz-14">
                <v-list-item-title class="font-weight-regular" v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-slot:actions>
              <v-icon size="20" class="stroke-grey"> $chevronRight</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list v-if="item.subpages" class="ma-0 pa-0 expand-list">
              <v-list-item
                class="rounded-0 mb-1 pl-7"
                style="height: unset; min-height: 10px; background-color: transparent !important"
                v-for="(link, index) in item.subpages"
                :key="index + 'subpage' + link.name"
                :to="link.url"
                :class="{'subpage-title': !link.url}">
                <v-list-item-content class="py-0 ml-9" :class="{'fz-14': link.url}">
                  {{ link.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels
        v-model="accordeon.accordeonMMShow"
        v-if="permission('readManagement')"
        accordion
        class="elevation-0 nav-accordeon">
        <v-expansion-panel v-for="(item, i) in filterByPermissions(accordeonMM)" :key="i">
          <v-expansion-panel-header
            class="pa-0"
            style="height: 44px; min-height: 44px"
            @click="$emit('showSidebar')">
            <v-list-item
              class="rounded-0 v-list-item--link pl-7"
              dense
              style="height: 44px; min-height: 44px"
              :class="{'v-list-item--active': item.name === $route.name}">
              <v-list-item-icon class="stroke-grey mr-3 my-auto">
                <v-icon size="20" class="my-auto" v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content class="fz-14">
                <v-list-item-title class="font-weight-regular" v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-slot:actions>
              <v-icon size="20" class="stroke-grey"> $chevronRight</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list v-if="item.subpages" class="ma-0 pa-0 expand-list">
              <v-list-item
                class="rounded-0 mb-1 pl-7"
                style="height: unset; min-height: 10px; background-color: transparent !important"
                v-for="(link, index) in item.subpages"
                :key="index + 'subpage' + link.name"
                :to="link.url"
                :class="{'subpage-title': !link.url}">
                <v-list-item-content class="py-0 ml-9" :class="{'fz-14': link.url}">
                  {{ link.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels
        v-model="accordeon.accordeonLBShow"
        v-if="permission('readLinkbuilding')"
        accordion
        class="elevation-0 nav-accordeon">
        <v-expansion-panel v-for="(item, i) in filterByPermissions(accordeonLB)" :key="i">
          <v-expansion-panel-header
            class="pa-0"
            style="height: 44px; min-height: 44px"
            @click="$emit('showSidebar')">
            <v-list-item
              class="rounded-0 v-list-item--link pl-7"
              style="height: 44px; min-height: 44px"
              dense
              :class="{'v-list-item--active': item.name === $route.name}">
              <v-list-item-icon class="stroke-grey mr-3 my-auto">
                <v-icon size="20" class="my-auto" v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content class="fz-14">
                <v-list-item-title class="font-weight-regular" v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-slot:actions>
              <v-icon size="20" class="stroke-grey"> $chevronRight</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list v-if="item.subpages" class="ma-0 pa-0 expand-list">
              <v-list-item
                class="rounded-0 mb-1 pl-7"
                style="height: unset; min-height: 10px; background-color: transparent !important"
                v-for="(link, index) in item.subpages"
                :key="index + 'subpage' + link.name"
                :to="link.url"
                :class="{'subpage-title': !link.url}">
                <v-list-item-content class="py-0 ml-9" :class="{'fz-14': link.url}">
                  {{ link.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels
        v-model="accordeon.accordeonCMShow"
        v-if="permission('readContentMarketing')"
        accordion
        class="elevation-0 nav-accordeon">
        <v-expansion-panel v-for="(item, i) in filterByPermissions(accordeonCM)" :key="i">
          <v-expansion-panel-header
            class="pa-0"
            style="height: 44px; min-height: 44px"
            @click="$emit('showSidebar')">
            <v-list-item
              class="rounded-0 v-list-item--link pl-7"
              dense
              style="height: 44px; min-height: 44px"
              :class="{'v-list-item--active': item.name === $route.name}">
              <v-list-item-icon class="stroke-grey mr-3 my-auto">
                <v-icon size="20" class="my-auto" v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content class="fz-14">
                <v-list-item-title class="font-weight-regular" v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-slot:actions>
              <v-icon size="20" class="stroke-grey"> $chevronRight</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list v-if="item.subpages" class="ma-0 pa-0 expand-list">
              <v-list-item
                class="rounded-0 mb-1 pl-7"
                style="height: unset; min-height: 10px; background-color: transparent !important"
                v-for="(link, index) in item.subpages"
                :key="index + 'subpage' + link.name"
                :to="link.url"
                :class="{'subpage-title': !link.url}">
                <v-list-item-content class="py-0 ml-9" :class="{'fz-14': link.url}">
                  {{ link.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels
        v-model="accordeon.accordeonMPShow"
        v-if="permission('readMarketingPlan')"
        accordion
        class="elevation-0 nav-accordeon">
        <v-expansion-panel v-for="(item, i) in filterByPermissions(accordeonMP)" :key="i">
          <v-expansion-panel-header
            class="pa-0"
            style="height: 44px; min-height: 44px"
            @click="$emit('showSidebar')">
            <v-list-item
              class="rounded-0 v-list-item--link pl-7"
              dense
              style="height: 44px; min-height: 44px"
              :class="{'v-list-item--active': item.name === $route.name}">
              <v-list-item-icon class="stroke-grey mr-3 my-auto">
                <v-icon size="20" class="my-auto" v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content class="fz-14">
                <v-list-item-title class="font-weight-regular" v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-slot:actions>
              <v-icon size="20" class="stroke-grey"> $chevronRight</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list v-if="item.subpages" class="ma-0 pa-0 expand-list">
              <v-list-item
                class="rounded-0 mb-1 pl-7"
                style="height: unset; min-height: 10px; background-color: transparent !important"
                v-for="(link, index) in item.subpages"
                :key="index + 'subpage' + link.name"
                :to="link.url"
                :class="{'subpage-title': !link.url}">
                <v-list-item-content class="py-0 ml-9" :class="{'fz-14': link.url}">
                  {{ link.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-list-item
        v-if="permission('readSemantics')"
        class="rounded-0 ma-0 pl-7"
        dense
        to="/semantics"
        style="height: 44px; min-height: 44px">
        <v-list-item-icon class="stroke-grey mr-3 my-auto">
          <v-icon size="20" class="my-auto" v-text="'$menuSemantics'"></v-icon>
        </v-list-item-icon>

        <v-list-item-content class="fz-14">
          <v-list-item-title class="font-weight-regular" v-text="'Semantics'"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-expansion-panels
        v-if="permissionList('readFinanceOrders', 'readSuppliers')"
        v-model="accordeon.accordeonOShow"
        accordion
        class="elevation-0 nav-accordeon">
        <v-expansion-panel v-for="(item, i) in filterByPermissions(accordeonO)" :key="i">
          <v-expansion-panel-header
            class="pa-0"
            style="height: 44px; min-height: 44px"
            @click="$emit('showSidebar')">
            <v-list-item
              class="rounded-0 v-list-item--link pl-7"
              dense
              style="height: 44px; min-height: 44px"
              :class="{'v-list-item--active': item.name === $route.name}">
              <v-list-item-icon class="stroke-grey mr-3 my-auto">
                <v-icon size="20" class="my-auto" v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content class="fz-14">
                <v-list-item-title class="font-weight-regular" v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-slot:actions>
              <v-icon size="20" class="stroke-grey"> $chevronRight</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list v-if="item.subpages" class="ma-0 pa-0 expand-list">
              <v-list-item
                class="rounded-0 mb-1 pl-7"
                style="height: unset; min-height: 10px; background-color: transparent !important"
                v-for="(link, index) in item.subpages"
                :key="index + 'subpage' + link.name"
                :to="link.url"
                :class="{'subpage-title': !link.url}">
                <v-list-item-content class="py-0 ml-9" :class="{'fz-14': link.url}">
                  {{ link.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels
        v-model="accordeon.accordeonMTShow"
        v-if="permission('readMaintenance')"
        accordion
        class="elevation-0 nav-accordeon">
        <v-expansion-panel v-for="(item, i) in filterByPermissions(accordeonMT)" :key="i">
          <v-expansion-panel-header
            class="pa-0"
            style="height: 44px; min-height: 44px"
            @click="$emit('showSidebar')">
            <v-list-item
              class="rounded-0 v-list-item--link pl-7"
              dense
              style="height: 44px; min-height: 44px"
              :class="{'v-list-item--active': item.name === $route.name}">
              <v-list-item-icon class="stroke-grey mr-3 my-auto">
                <v-icon size="20" class="my-auto" v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content class="fz-14">
                <v-list-item-title class="font-weight-regular" v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-slot:actions>
              <v-icon size="20" class="stroke-grey"> $chevronRight</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list v-if="item.subpages" class="ma-0 pa-0 expand-list">
              <v-list-item
                class="rounded-0 mb-1 pl-7"
                style="height: unset; min-height: 10px; background-color: transparent !important"
                v-for="(link, index) in item.subpages"
                :key="index + 'subpage' + link.name"
                :to="link.url"
                :class="{'subpage-title': !link.url}">
                <v-list-item-content class="py-0 ml-9" :class="{'fz-14': link.url}">
                  {{ link.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels
        v-if="permission('readAffilateMarketing')"
        v-model="accordeon.accordeonAMShow"
        accordion
        class="elevation-0 nav-accordeon">
        <v-expansion-panel v-for="(item, i) in filterByPermissions(accordeonAM)" :key="i">
          <v-expansion-panel-header
            class="pa-0"
            style="height: 44px; min-height: 44px"
            @click="$emit('showSidebar')">
            <v-list-item
              class="rounded-0 v-list-item--link pl-7"
              dense
              style="height: 44px; min-height: 44px"
              :class="{'v-list-item--active': item.name === $route.name}">
              <v-list-item-icon class="stroke-grey mr-3 my-auto">
                <v-icon size="20" class="my-auto" v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content class="fz-14">
                <v-list-item-title class="font-weight-regular" v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-slot:actions>
              <v-icon size="20" class="stroke-grey"> $chevronRight</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list v-if="item.subpages" class="ma-0 pa-0 expand-list">
              <v-list-item
                class="rounded-0 mb-1 pl-7"
                style="height: unset; min-height: 10px; background-color: transparent !important"
                v-for="(link, index) in item.subpages"
                :key="index + 'subpage' + link.name"
                :to="link.url"
                :class="{'subpage-title': !link.url}">
                <v-list-item-content class="py-0 ml-9" :class="{'fz-14': link.url}">
                  {{ link.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-list-item
        v-if="permission('readReports')"
        class="rounded-0 mb-0 pl-7"
        dense
        to="/reports"
        style="height: 44px; min-height: 44px">
        <v-list-item-icon class="stroke-grey mr-3 my-auto">
          <v-icon size="20" class="my-auto" v-text="'$menuReports'"></v-icon>
        </v-list-item-icon>

        <v-list-item-content class="fz-14">
          <v-list-item-title class="font-weight-regular" v-text="'Reports'"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="permission('readTeam')"
        class="rounded-0 mb-2 pl-7"
        dense
        to="/team"
        style="height: 44px; min-height: 44px">
        <v-list-item-icon class="stroke-grey mr-3 my-auto">
          <v-icon size="20" class="my-auto" v-text="'$menuTeam'"></v-icon>
        </v-list-item-icon>

        <v-list-item-content class="fz-14">
          <v-list-item-title class="font-weight-regular" v-text="'Team'"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-row class="ma-0 flex-grow-1"></v-row>
    <v-sheet v-if="!sidebarMini" color="transparent" elevation="0" class="ma-0 pa-0">
      <v-sheet
        class="mx-6 mt-6 mb-0 rounded-3 border-grey px-4 pt-4 pb-0"
        style="box-shadow: 0px 16px 24px rgba(36, 36, 54, 0.05)"
        v-if="myBirthday.length">
        <v-col class="pa-0">
          <v-img
            width="60"
            height="60"
            max-width="60"
            max-height="60"
            class="mb-4 mx-auto"
            src="@/assets/img/Emoji-large.png"></v-img>
          <p class="fz-14 font-weight-medium text-center">
            Happy Birthday,<br />{{ myBirthday[0].fullName }}
          </p>
        </v-col>
      </v-sheet>
      <v-sheet
        v-else-if="birthday.length === 1"
        class="mx-6 mt-6 mb-0 rounded-3 border-grey px-4 pt-4 pb-0"
        style="box-shadow: 0px 16px 24px rgba(36, 36, 54, 0.05)">
        <v-row class="ma-0">
          <v-img
            width="20"
            height="20"
            max-width="20"
            max-height="20"
            class="mr-3"
            src="@/assets/img/Emoji.png"></v-img>
          <span class="fz-14 font-weight-medium">Birthday today</span>
        </v-row>
        <v-divider class="my-4"></v-divider>
        <v-row class="ma-0">
          <v-col class="pa-0 d-flex flex-column align-center">
            <v-avatar size="60" class="rounded-4 mx-auto mb-3">
              <img v-if="birthday[0].avatar" :src="birthday[0].avatar" alt="" />
              <AvatarsItem v-else :item="birthday[0]" customClass="no-avatar-style_HB" />
            </v-avatar>
            <p class="ma-0 fz-14 lh-5 text-center">
              {{ birthday[0].fullName }}
            </p>
            <p class="ma-0 fz-14 lh-5 text-grey mb-3 text-center">
              {{ agesToday(birthday[0].birthday) }} years old!
            </p>
            <v-row class="ma-0">
              <a target="_blank" :href="'http://t.me/' + birthday[0].telegram.replace('@', '')">
                <v-img width="44" height="44" src="@/assets/img/msg-icon.png"></v-img>
              </a>
              <a target="_blank" :href="'http://t.me/' + birthday[0].telegram.replace('@', '')">
                <v-img width="44" height="44" src="@/assets/img/call-icon.png"></v-img>
              </a>
            </v-row>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet
        v-else-if="birthday.length > 1"
        class="mx-6 mt-6 mb-0 rounded-3 border-grey px-4 pt-4 pb-0"
        style="box-shadow: 0px 16px 24px rgba(36, 36, 54, 0.05)">
        <v-row class="ma-0">
          <v-img
            width="20"
            height="20"
            max-width="20"
            max-height="20"
            class="mr-3"
            src="@/assets/img/Emoji.png"></v-img>
          <span class="fz-14 font-weight-medium">Birthday today</span>
        </v-row>
        <v-divider class="my-4"></v-divider>
        <v-list class="pa-0">
          <v-list-item
            class="rounded-0 mb-4 pa-0"
            dense
            style="min-height: 28px !important"
            v-for="(item, index) in birthday"
            :key="index">
            <v-list-item-icon class="stroke-grey mr-3 my-0">
              <v-avatar
                size="28"
                class="rounded-lg"
                :color="item.avatar || item.team ? 'transparent' : 'primary'">
                <img v-if="item.avatar" :src="item.avatar" alt="" />
                <AvatarsItem v-else :item="item" customClass="no-avatar-style_Header" />
              </v-avatar>
            </v-list-item-icon>
            <v-list-item-content class="fz-12 pa-0">
              <p class="ma-0">{{ item.fullName }}</p>
              <span class="text-grey"> {{ agesToday(item.birthday) }} years old! </span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-sheet>
    <v-sheet
      v-else-if="sidebarMini && (myBirthday.length || birthday.length)"
      color="transparent"
      elevation="0"
      class="my-0 mx-auto pa-0">
      <v-img width="24" height="24" max-width="24" max-height="24" src="@/assets/img/Emoji.png"></v-img>
    </v-sheet>
    <v-row class="ma-0 flex-grow-0">&nbsp;</v-row>
    <v-dialog
      v-model="birthdayShow"
      content-class="width-unset  elevation-0 rounded-4"
      overlay-color="rgba(57,57,74,0.75)"
      overlay-opacity="1">
      <v-card v-if="birthdayShow && birthday.length > 1" class="relative d-flex align-center">
        <v-icon
          class="stroke-grey"
          style="position: absolute; right: 16px; top: 16px; z-index: 9999; cursor: pointer"
          @click="closeWindow">
          $close
        </v-icon>
        <v-img width="90" height="379" class="flex-grow-0" src="@/assets/img/bth-bg-left.png"></v-img>
        <v-sheet class="mx-12">
          <p class="fz-30 font-weight-medium lh-3 text-center mb-12">Birthday today!</p>
          <v-row class="ma-0">
            <template v-for="(item, index) in birthday">
              <v-divider vertical class="mx-9 mb-4" v-if="index" :key="index + 'modalDivider'"></v-divider>
              <v-col
                class="pa-0 align-center d-flex flex-column"
                style="min-width: 135px"
                :key="index + 'modal'">
                <v-avatar
                  size="72"
                  class="rounded-4 mb-4"
                  :color="item.avatar || item.team ? 'transparent' : 'primary'">
                  <img v-if="item.avatar" :src="item.avatar" alt="" />
                  <AvatarsItem v-else :item="item" customClass="no-avatar-style_HB" />
                </v-avatar>
                <p class="ma-0 fz-18 font-weight-medium mb-1" v-text="item.fullName"></p>
                <span class="text-grey fz-14"> Today is {{ agesToday(item.birthday) }} years old! </span>
                <v-row class="ma-0 mt-3">
                  <a target="_blank" :href="'http://t.me/' + item.telegram.replace('@', '')">
                    <v-img width="64" height="64" src="@/assets/img/msg-icon.png"></v-img>
                  </a>
                  <a target="_blank" :href="'http://t.me/' + item.telegram.replace('@', '')">
                    <v-img width="64" height="64" src="@/assets/img/call-icon.png"></v-img>
                  </a>
                </v-row>
              </v-col>
            </template>
          </v-row>
        </v-sheet>
        <v-img width="90" height="379" class="flex-grow-0" src="@/assets/img/bth-bg-right.png"></v-img>
      </v-card>
      <v-card v-if="birthdayShow && birthday.length === 1" class="relative d-flex align-center">
        <v-icon
          class="stroke-grey"
          style="position: absolute; right: 16px; top: 16px; z-index: 9999; cursor: pointer"
          @click="closeWindow">
          $close
        </v-icon>
        <v-img width="90" height="332" class="flex-grow-0" src="@/assets/img/bth-bg-left.png"></v-img>
        <v-sheet class="mx-12">
          <p class="fz-30 font-weight-medium lh-3 text-center mb-7">Birthday today!</p>
          <v-row class="ma-0">
            <v-col class="pa-0 align-center d-flex flex-column" style="min-width: 135px">
              <v-row class="ma-0 mb-7 align-center">
                <a target="_blank" :href="'http://t.me/' + birthday[0].telegram.replace('@', '')">
                  <v-img
                    width="64"
                    height="64"
                    src="@/assets/img/msg-icon.png"
                    class="mr-7 mt-3 mb-n1"></v-img>
                </a>
                <v-avatar
                  class="rounded-4"
                  size="72"
                  :color="birthday[0].avatar || birthday[0].team ? 'transparent' : 'primary'">
                  <img v-if="birthday[0].avatar" :src="birthday[0].avatar" alt="" />
                  <AvatarsItem v-else :item="birthday[0]" customClass="no-avatar-style_HB" />
                </v-avatar>
                <a target="_blank" :href="'http://t.me/' + birthday[0].telegram.replace('@', '')">
                  <v-img
                    width="64"
                    height="64"
                    class="ml-7 mt-3 mb-n1"
                    src="@/assets/img/call-icon.png"></v-img>
                </a>
              </v-row>
              <p class="ma-0 fz-22 font-weight-medium mb-1" v-text="birthday[0].fullName"></p>
              <span class="text-grey fz-16"> Today is {{ agesToday(birthday[0].birthday) }} years old! </span>
            </v-col>
          </v-row>
        </v-sheet>
        <v-img width="90" height="332" class="flex-grow-0" src="@/assets/img/bth-bg-right.png"></v-img>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="myBirthdayShow"
      content-class="width-unset  elevation-0 rounded-4"
      overlay-color="rgba(57,57,74,0.75)"
      overlay-opacity="1">
      <v-card v-if="myBirthdayShow" class="relative d-flex align-center">
        <v-icon
          class="stroke-grey"
          style="position: absolute; right: 16px; top: 16px; z-index: 9999; cursor: pointer"
          @click="birthdayCheck">
          $close
        </v-icon>
        <v-img width="90" height="282" class="flex-grow-0" src="@/assets/img/bth-bg-left.png"></v-img>
        <v-sheet class="mx-12">
          <p class="fz-30 font-weight-medium lh-3 text-center mb-0">Happy Birthday To You,</p>
          <p class="fz-30 font-weight-medium lh-3 text-center mb-7">
            {{ myBirthday[0].fullName }}
          </p>
          <v-row class="ma-0 justify-center">
            <v-avatar
              size="72"
              class="rounded-4 mb-4"
              :color="myBirthday[0].avatar || myBirthday[0].team ? 'transparent' : 'primary'">
              <img v-if="myBirthday[0].avatar" :src="myBirthday[0].avatar" alt="" />
              <AvatarsItem v-else :item="myBirthday[0]" customClass="no-avatar-style_HB" />
            </v-avatar>
          </v-row>
        </v-sheet>
        <v-img width="90" height="282" class="flex-grow-0" src="@/assets/img/bth-bg-right.png"></v-img>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="width-unset  elevation-0 rounded-4"
      overlay-color="rgba(57,57,74,0.75)"
      overlay-opacity="1"
      max-width="700"
      :value="welcomeShow"
      @click:outside="$emit('checkBirthday')">
      <v-card
        v-if="welcomeShow"
        class="relative d-flex align-center flex-column py-12 justify-center"
        style="padding-left: 73px; padding-right: 73px">
        <v-icon
          class="stroke-grey"
          style="position: absolute; right: 16px; top: 16px; z-index: 9999; cursor: pointer"
          @click="$emit('checkBirthday')"
        >
          $close
        </v-icon>
        <v-sheet class="d-flex justify-center flex-column align-center">
          <p class="fz-30 font-weight-medium lh-3 text-center mb-0">Welcome to HubNero,</p>
          <p class="fz-30 font-weight-medium lh-3 text-center mb-7">{{ me.firstName }}!</p>
          <v-img src="@/assets/svg/Greetings.svg" width="300" height="300" class="mb-7"></v-img>
          <span class="font-weight-medium" style="align-self: start"> Last updates: </span>
          <ul class="mb-7">
            <li>
              <a @click="route('/organic-search/top-pages')">Top Pages</a>
              : fixed bug when custom period selected
            </li>
            <li>
              <a @click="route('/maintenance/tools')">Tools</a>
              : usability/validation update, active option
            </li>
            <li>
              <a @click="route('/maintenance/hostings-registrators')">Hostings & Registrators</a>
              : clean up fields, functional update
            </li>
            <li>Changed order staging logic (tool-based)</li>
          </ul>
          <v-btn
            elevation="0"
            block
            color="primary"
            height="48"
            class="fz-14 rounded-3 text-none font-weight-regular"
            @click="$emit('checkBirthday')"
          >Let’s Go!
          </v-btn>
        </v-sheet>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="width-unset  elevation-0 rounded-4"
      overlay-color="rgba(57,57,74,0.75)"
      overlay-opacity="1"
      max-width="600"
      :value="cookieReminder"
    >
      <v-card
        class="relative d-flex align-center flex-column py-12 justify-center"
        style="padding-left: 73px; padding-right: 73px">
        <v-icon
          class="stroke-grey"
          style="position: absolute; right: 16px; top: 16px; z-index: 9999; cursor: pointer"
          @click="cookieReminder = false"
        >
          $close
        </v-icon>
        <v-sheet class="d-flex justify-center flex-column align-center">
          <p class="fz-30 font-weight-medium lh-3 text-center mb-0">Don't forget to clear your cookie once a week!</p>
        </v-sheet>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import gql from 'graphql-tag';
import AvatarsItem from '@/components/md-ui/table/item/AvatarsItem';
export default {
  name: 'SideBar',
  props: ['sidebarMini', 'myBirthday', 'birthday', 'welcomeShow'],
  components: {
    AvatarsItem,
  },
  data() {
    const result = {
      isUserDeveloper: false,
      showWIPTabs: process.env.TARGET !== 'production',
      menu: [
        {
          name: 'Insights',
          url: '/',
          icon: '$menuInsights',
          permission: 'useInsights',
        },
      ],
      accordeon: {
        accordeonMTShow: null,
        accordeonFNShow: null,
        accordeonCMShow: null,
        accordeonMPShow: null,
        accordeonLBShow: null,
        accordeonOShow: null,
        accordeonMMShow: null,
        accordeonAMShow: null,
      },
      isFirstMount: true,
      brefShow: null,
      briefShow: null,
      accordeonFN: [
        {
          name: 'Finance',
          icon: '$menuFinance',
          subpages: [
            {
              name: 'Tasks',
              url: '/finance/tasks',
              permission: 'readFinanceTasks',
            },
            {
              name: 'Balance',
              url: '/finance/balance',
              permission: 'readFinanceBalance',
            },
            {
              name: 'My Cards',
              url: '/finance/my-cards',
              permission: 'readFinanceCards',
            },
          ],
        },
      ],
      accordeonMM: [
        {
          name: 'Management',
          icon: '$management',
          subpages: [
            {
              name: 'PnL',
              url: '/management/pl',
              permission: 'readManagementPl',
            },
            {
              name: 'Unit Economic',
              url: '/management/unit-economic',
              permission: 'readManagementUnitEconomics',
            },
            {
              name: 'Revenue Cabs',
              url: '/management/RevenueCabs',
              permission: 'readFinanceRevenueCabs',
            },
          ],
        },
      ],
      accordeonO: [
        {
          name: 'Orders',
          icon: '$menuContentMarketing',
          subpages: [
            {
              name: 'Order Items',
              url: '/orders/orderitems',
              permission: 'readFinanceOrders',
            },
            {
              name: 'Suppliers',
              url: '/orders/suppliers',
              permission: 'readSuppliers',
            },
          ],
        },
      ],
      accordeonMT: [
        {
          name: 'Maintenance',
          icon: '$menuMaintenance',
          subpages: [
            {
              name: 'Tools',
              url: '/maintenance/tools',
              permission: 'readMaintenanceTools',
            },
            {
              name: 'Projects',
              url: '/maintenance/projects',
              permission: 'readMaintenanceProjects',
            },
            {
              name: 'Google Connections',
              url: '/maintenance/gsc-connections',
              permission: 'readMaintenanceGscConnections',
            },
            {
              name: 'Emails',
              url: '/maintenance/emails',
              permission: 'readMaintenanceEmails',
            },
            {
              name: 'Hostings & Registrators',
              url: '/maintenance/hostings-registrators',
              permission: 'readMaintenanceHostingsRegistrators',
            },
            {
              name: 'Project Management',
              url: '/maintenance/project-management',
              permission: 'readMaintenanceProjectManagement',
            },
          ],
        },
      ],
      accordeonAM: [
        {
          name: 'Affiliate Marketing',
          icon: '$menuAffiliateMarketing',
          subpages: [
            {
              name: 'Brand Board',
              url: '/affiliate-marketing/brand-board',
              permission: 'readAffilateMarketing',
            },
          ],
        },
      ],
      accordeonCM: [
        {
          name: 'Content Marketing',
          icon: '$menuContentMarketing',
          subpages: [
            {
              name: 'Content Items',
              url: '/contentmarketing/contentitems',
              permission: 'readContentMarketingContent',
            },
            {
              name: 'Copywriters',
              url: '/contentmarketing/copywriters',
              permission: 'readContentMarketingCopywritersTab',
            },
          ],
        },
      ],
      accordeonMP: [
        {
          name: 'Marketing Plan',
          icon: '$menuMarketingPlan',
          subpages: [
            {
              name: 'Content Plan',
              url: '/marketing-plan/content-plan',
              permission: 'readPlanningContent',
            },
            {
              name: 'Link Building Plan',
              url: '/marketing-plan/link-building-plan',
              permission: 'readPlanningLinkbuilding',
            },
            {
              name: 'PBN Plan',
              url: '/marketing-plan/pbn-plan',
              permission: 'readPbnPlanning',
            },
          ],
        },
      ],
      accordeonLB: [
        {
          name: 'Link Building',
          icon: '$menuLinkBuilding',
          subpages: [
            {
              name: 'Link Building Item',
              url: '/link-building/link-building-item',
              permission: 'readLinkbuildingItem',
            },
            {
              name: 'Accounts',
              url: '/link-building/accounts',
              permission: 'readLinkbuildingAccount',
            },
            {
              name: 'Outreach Base',
              url: '/link-building/outreach-base',
              permission: 'readLinkbuildingOutreachBase',
            },
          ],
        },
      ],
      myBirthdayShow: false,
      birthdayShow: false,
      cookieReminder: false,
      prevRoute: null,
      brefList: [
        {
          name: 'Traffic',
          url: '/brief/traffic',
        },
        {
          name: 'Keyword description',
          url: '/brief/keyword-description',
        },
        {
          name: 'Ref Domains',
          url: '/brief/ref-domains',
        },
        {
          name: 'Content',
          url: '/brief/content',
        },
        {
          name: 'Costs',
          url: '/brief/costs',
        },
      ],
      watchTime: {
        day: new Date().getDay(),
        hour: new Date().getHours(),
        minute: new Date().getMinutes(),
      },
    };
    return result;
  },
  computed: {
    permissions() {
      return this.$store.getters.getMe ? this.$store.getters.getMe.tabPermissions : {};
    },
    christmasMode() {
      return this.$store.getters.getChristmasMode;
    },
    selectedTool() {
      return this.$store.getters.getSelectedTool;
    },
    me() {
      return this.$store.getters.getMe;
    },
  },
  updated() {
    this.getMe();
  },
  mounted() {
    const accord = JSON.parse(localStorage.getItem('sideAccord'));
    this.accordeon = {
      ...this.accordeon,
      ...accord,
    };
    const path = this.$route.path;
    if (this.isFirstMount) {
      setTimeout(() => {
        if (this.$route.path === path) {
          this.activity();
        }
      }, this.$store.state.triggerMonitoringTimeout);
      this.isFirstMount = false;
    }

    this.checkSchedule();
    setInterval(this.checkSchedule, 60000);
  },
  methods: {
    brefUpdate() {
      this.$store.commit('updateDomainPortal', '');
    },
    showBirth() {
      const self = this;
      setTimeout(function() {
        if (self.myBirthday.length) self.myBirthdayShow = true;
        else if (self.birthday.length) self.birthdayShow = true;
      }, 0);
    },
    birthdayCheck() {
      this.myBirthdayShow = false;
      if (this.birthday.length) this.birthdayShow = true;
    },
    agesToday(birthday) {
      const year = new Date(birthday).getFullYear();
      const currentYear = new Date().getFullYear();
      return currentYear - year;
    },
    filterByPermissions(accordeon) {
      return accordeon.map((e) => {
        return {
          ...e,
          subpages: e.subpages.filter((e) => this.permissions[e.permission]),
        };
      });
    },
    permissionList(...state) {
      const booleans = state.map((permission) => this.permission(permission));
      return booleans.find((e) => e);
    },
    permission(state) {
      return this.permissions[state];
    },
    route(r) {
      this.$router.push(r);
      this.$emit('checkBirthday');
    },
    closeWindow() {
      this.birthdayShow = false;
    },
    cookieReminderOpen() {
      this.cookieReminder = true;
    },
    checkSchedule() {
      const today = new Date();
      const currentDay = today.getDay();
      const currentHour = today.getHours();
      const currentMinute = today.getMinutes();
      if (currentDay === 1 && currentHour === 12 && currentMinute === 0) {
        this.cookieReminderOpen();
      }
    },
    async getMe() {
      await this.$apollo
          .query({
            query: gql`
            query getMyID {
              auth {
                me {
                  id
                  team {
                    name
                    defaultColor
                  }
                }
              }
            }
          `,
          })
          .then((data) => {
            const {team} = data.data.auth.me;
            if (team && team.name === 'Frosty') {
              this.isUserDeveloper = true;
            }
          })
          .catch((err) => {
            document.cookie = 'Login=false';
            console.log(err);
            this.$router.push('/login');
          });
    },
    async activity() {
      await this.$apollo
          .mutate({
            mutation: gql`
            mutation Activity($path: String!) {
              technical {
                activity {
                  writeActivityPath(path: $path)
                }
              }
            }
          `,
            variables: {
              path: this.$route.path,
            },
            fetchPolicy: 'no-cache',
          })
          .then(() => {
            if (process.env.TARGET !== 'development') {
              console.clear();
              console.log(`Page: ${this.$route.name}`);
              console.log(`Route: /md${this.$route.fullPath}`);
              console.log(`%cCheck-in`, 'color: green');
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
  watch: {
    $route(to) {
      if (!this.$route.path.includes('brief')) {
        console.clear();
        console.log(`Page: ${to.name}`);
        console.log(`Route: /md${to.fullPath}`);
      }

      const path = this.$route.path;
      if (!this.isFirstMount) {
        setTimeout(() => {
          if (this.$route.path === path) {
            this.activity();
          }
        }, this.$store.state.triggerMonitoringTimeout);
      }
    },
    selectedTool: {
      handler() {
        if (this.selectedTool.selectedDomain) this.brefShow = 0;
      },
      deep: true,
    },
    accordeon: {
      handler() {
        localStorage.setItem('sideAccord', JSON.stringify(this.accordeon));
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.sidebar {
  .v-list-item--link:before {
    background-color: #8f20ef !important;
    position: absolute;
    right: unset;
    width: 4px;
    top: 8px;
    bottom: 8px;
    border-radius: 0 10px 10px 0;
    opacity: 0 !important;
    transition: 0.26s;
  }

  .v-list-item--active {
    transition: 0.26s;

    .v-icon {
      stroke: #8f20ef !important;
    }

    &:before {
      opacity: 1 !important;
      transition: 0.26s;
    }
  }

  .theme--light.v-list-item:not(.v-list-item--disabled) {
    color: #2a355a !important;

    .v-icon {
      transition: 0.26s;
    }

    &:hover {
      color: #8f20ef !important;
      .v-icon {
        stroke: #8f20ef;
      }
    }
  }

  .logoFill {
    svg {
      fill: #242436;
    }
  }
}

.nav-accordeon {
  .v-expansion-panel {
    background-color: transparent !important;

    &:before,
    &:after {
      content: none !important;
    }
  }

  .v-expansion-panel-header__icon {
    position: absolute;
    left: 192px;
    background: #f5f7fb !important;
    border-radius: 6px;
  }

  .v-expansion-panel--active
    .v-expansion-panel-header--active
    .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate)
    .v-icon {
    transform: rotate(90deg) !important;
  }

  .v-expansion-panel-content__wrap {
    padding: 0 !important;

    .v-list-item:first-child {
      margin-top: 8px;
    }

    .expand-list {
      position: relative;

      &:before {
        content: '';
        width: 1px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 39px;
        background: #e5e8ef;
      }

      .v-list-item--active {
        color: #8f20ef !important;

        &:before {
          opacity: 0 !important;
        }
      }
    }
  }

  .subpage-title {
    color: #8f20ef !important;
  }
}
</style>
