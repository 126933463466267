<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="8" fill="#F9F4FE" />
    <path
      d="M7.66669 21.8333C7.66669 20 9.16669 18.5 11 18.5H14.3334C16.1667 18.5 17.6667 20 17.6667 21.8333"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.75 11C15.9167 12.1667 15.9167 14 14.75 15.0833C13.5834 16.1667 11.75 16.25 10.6667 15.0833C9.58337 13.9167 9.50004 12.1667 10.5834 11C11.6667 9.83333 13.5834 9.91667 14.75 11"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.3334 17.6667H21.8334C23.25 17.6667 24.3334 18.75 24.3334 20.1667"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.0833 11.5833C22.9167 12.4167 22.9167 13.75 22.0833 14.5C21.25 15.25 19.9167 15.3333 19.1667 14.5C18.4167 13.6667 18.3333 12.3333 19.1667 11.5833C19.9167 10.8333 21.25 10.8333 22.0833 11.5833"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'CPСopywriter',
};
</script>

<style scoped></style>
