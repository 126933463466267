import { render, staticRenderFns } from "./GroupIcon.vue?vue&type=template&id=194b8e5e&scoped=true&"
import script from "./GroupIcon.vue?vue&type=script&lang=js&"
export * from "./GroupIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "194b8e5e",
  null
  
)

export default component.exports