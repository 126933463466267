<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7505 30.75H6.666C5.883 30.75 5.25 30.1155 5.25 29.334V23.667C5.25 22.884 5.8845 22.251 6.666 22.251H13.749"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.2495 6.666V30.75H29.3325C30.1155 30.75 30.7485 30.1155 30.7485 29.334V6.666C30.7485 5.883 30.114 5.25 29.3325 5.25H23.6655C22.884 5.25 22.2495 5.8845 22.2495 6.666H22.2495Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.2495 30.75H13.749V15.1665C13.749 14.3835 14.3835 13.7505 15.165 13.7505H22.248"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Tier',
};
</script>

<style scoped></style>
