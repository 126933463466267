<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 13.5V10.5H21" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8.667 4.5H27.333C28.806 4.5 30 5.8425 30 7.5V28.5C30 30.1575 28.806 31.5 27.333 31.5H8.667C7.194 31.5 6 30.1575 6 28.5V7.5C6 5.8425 7.194 4.5 8.667 4.5Z"
          stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 20.25H24" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 25.5H18" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24 10.5L19.5 15L15.429 12L12 15" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: 'AnalyticsTemplate',
};
</script>

<style scoped></style>
