<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.591 8.40901C12.4697 9.2877 12.4697 10.7123 11.591 11.591C10.7123 12.4697 9.2877 12.4697 8.40901 11.591C7.53033 10.7123 7.53033 9.2877 8.40901 8.40901C9.2877 7.53033 10.7123 7.53033 11.591 8.40901"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.37505 10C4.37505 10.2475 4.39755 10.495 4.42755 10.735L3.10422 11.77C2.81089 12 2.73089 12.4108 2.91755 12.7333L4.09422 14.7692C4.28005 15.0917 4.67505 15.2275 5.02089 15.0892L6.20589 14.6133C6.44005 14.5192 6.70089 14.5567 6.91089 14.6958C7.09422 14.8175 7.28422 14.9292 7.48089 15.0292C7.70589 15.1433 7.86922 15.3475 7.90505 15.5975L8.08589 16.8583C8.13839 17.2267 8.45422 17.5 8.82589 17.5H11.1734C11.5451 17.5 11.8609 17.2267 11.9134 16.8583L12.0942 15.5983C12.1301 15.3483 12.2951 15.1425 12.5209 15.0292C12.7167 14.9308 12.9059 14.82 13.0884 14.6992C13.3001 14.5592 13.5617 14.5192 13.7967 14.6142L14.9792 15.0892C15.3242 15.2275 15.7192 15.0917 15.9059 14.7692L17.0826 12.7333C17.2692 12.4108 17.1892 11.9992 16.8959 11.77L15.5726 10.735C15.6026 10.495 15.6251 10.2475 15.6251 10C15.6251 9.7525 15.6026 9.505 15.5726 9.265L16.8959 8.23C17.1892 8 17.2692 7.58917 17.0826 7.26667L15.9059 5.23083C15.7201 4.90833 15.3251 4.7725 14.9792 4.91083L13.7967 5.38583C13.5617 5.48 13.3001 5.44083 13.0884 5.30083C12.9059 5.18 12.7167 5.06917 12.5209 4.97083C12.2951 4.8575 12.1301 4.65167 12.0942 4.40167L11.9142 3.14167C11.8617 2.77333 11.5459 2.5 11.1742 2.5L8.82672 2.5C8.45505 2.5 8.13922 2.77333 8.08672 3.14167L7.90505 4.40333C7.86922 4.6525 7.70505 4.8575 7.48089 4.97167C7.28422 5.07167 7.09422 5.18417 6.91089 5.305C6.70005 5.44333 6.43922 5.48083 6.20505 5.38667L5.02089 4.91083C4.67505 4.7725 4.28005 4.90833 4.09422 5.23083L2.91755 7.26667C2.73089 7.58917 2.81089 8.00083 3.10422 8.23L4.42755 9.265C4.39755 9.505 4.37505 9.7525 4.37505 10V10Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'menuMaintenance',
};
</script>

<style scoped></style>
