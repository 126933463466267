<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path id="Path" d="M17.9202 11.6569V18.3406L12.6665 21.543" stroke="#8591AE" stroke-linecap="round"
            stroke-linejoin="round"/>
      <path id="Path_2"
            d="M14.5482 30.005C8.51769 28.5873 4.34145 23.0897 4.59298 16.8999C4.84451 10.7101 9.45306 5.56939 15.5787 4.64569C20.2824 3.93641 24.8644 5.86667 27.6735 9.44521C27.8223 9.63476 27.9661 9.82894 28.1047 10.0276"
            stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round"/>
      <g id="Group 31">
        <path id="Path_3" fill-rule="evenodd" clip-rule="evenodd"
              d="M32.0916 31.4997H20.289C19.7876 31.4997 19.3811 31.0932 19.3811 30.5918V29.8546C19.3871 27.7571 21.0859 26.0583 23.1834 26.0523H29.1973C31.2947 26.0583 32.9936 27.7571 32.9995 29.8546V30.5918C32.9995 31.0932 32.5931 31.4997 32.0916 31.4997Z"
              stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round"/>
        <path id="Path_4" fill-rule="evenodd" clip-rule="evenodd"
              d="M26.19 22.2632C24.1857 22.2591 22.5637 20.6322 22.5657 18.6279C22.5677 16.6237 24.193 15 26.1973 15C28.2015 15 29.8268 16.6237 29.8288 18.6279C29.8308 20.6322 28.2088 22.2591 26.2045 22.2632H26.19Z"
              stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  name: 'UsersPerformance',
};
</script>

<style scoped>

</style>
