<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 6V14" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14 10H6" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'btnPlus',
};
</script>

<style scoped></style>
