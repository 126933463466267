import { render, staticRenderFns } from "./lbTarget.vue?vue&type=template&id=23414e70&scoped=true&"
import script from "./lbTarget.vue?vue&type=script&lang=js&"
export * from "./lbTarget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23414e70",
  null
  
)

export default component.exports