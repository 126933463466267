import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {
  AnalyticsTemplate,
  NEW_PAGE_TRANSLATE,
  PAGE_CONTENT_PLANNED,
  PAGE_CONTENT_CHANGED,
  PAGE_META_CHANGED,
  URLProfiler,
  PayPal,
  btcsmall,
  cardsmall,
  RadioOff,
  RadioOn,
  itemFolders,
  itemDollar,
  itemTasks,
  Summary,
  telegram,
  Logo,
  menuContentMarketing,
  menuAffiliateMarketing,
  menuFinance,
  menuInsights,
  menuLinkBuilding,
  menuMaintenance,
  menuMyDashboard,
  dashboardOpen,
  dashboardClose,
  menuReports,
  menuSemantics,
  menuTeam,
  menuTools,
  notification,
  searchIcon,
  overview,
  backlinks,
  newContent,
  updatedContent,
  moveUp,
  moveDown,
  chevronDown,
  chevronLeft,
  chevronRight,
  burger,
  close,
  Plus,
  Minus,
  Delete,
  DeleteDocument,
  edit,
  AddNew,
  save,
  cloud,
  tableSort,
  tableSortUp,
  tableSortDown,
  checkbox,
  checkboxOn,
  googleLogo,
  fiverrLogo,
  snackSuccess,
  snackError,
  snackWarning,
  snackInfo,
  traffic,
  keywords,
  content,
  spending,
  income,
  profit,
  PAGE_CREATED,
  PAGE_DELETED,
  PAGE_REDIRECTED,
  CONTENT_PROOF_READING,
  CONTENT_NEW_PAGE,
  CONTENT_UPDATE,
  LINK_NEW,
  LINK_DELETED,
  LINK_PLANNED,
  CopyIcon,
  spamDownload,
  approved,
  approvedUser,
  rejected,
  btnPlus,
  btnSend,
  inprogress,
  itemComplete,
  loggedUsers,
  profilerOperations,
  profilerPosition,
  profilerMainKeyword,
  profilerKeywords,
  searchInputIcon,
  copywriters,
  global,
  arrowLeft,
  tooltip,
  tooltipViolet,
  paymentDetails,
  relatedTo,
  searchDark,
  brefProjectStart,
  brefUrlStart,
  dots,
  btnApply,
  btnEdit,
  btnDelete,
  btnCancel,
  brokenLine,
  update,
  lbTarget,
  lbReferring,
  phone,
  logout,
  gmail,
  Backlink,
  BacklinkDeleted,
  Budgets,
  ContentAudit,
  Crossmap,
  Economics,
  Tier,
  OrderItemIcon,
  ProjectIcon,
  ProjectGroupIcon,
  GroupIcon,
  Usd,
  CardRus,
  CardUa,
  Bitcoin,
  Eth,
  Fiverr,
  WirePay,
  UploadLink,
  Ga,
  user,
  company,
  LBOBcard,
  LBOBplanet,
  LBOBstack,
  UploadData,
  Upload,
  success,
  errorIcon,
  checkboxMinus,
  refresh,
  menuMarketingPlan,
  CPArticles,
  CPUpdate,
  CPСopywriter,
  CPworks,
  CPSpending,
  Capitalist,
  Card,
  NewPayPal,
  LBPgear,
  LBPtime,
  LBPanchor,
  copyData,
  refreshData,
  PBNGear,
  PBNStack,
  PBNTime,
  PBNWarning,
  arrowShuffle,
  searchGreyIcon,
  licenseLogo,
  management,
  check,
  PBNPerformance,
  ProjectContent,
  UsersActivity,
  UsersPerformance,
  PassportReport,
  KeywordsReport,
  Conversions,
  RlTop,
  HeatmapRD,
  HeatmapURL,
  CPWarning,

} from '@/components/svg/index';

Vue.use(Vuetify);

export default new Vuetify({
  defaults: {
    global: {
      ripple: false,
    },
    VButton: {
      elevation: 0,
    },
  },
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1264,
      lg: 1780,
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#8F20EF',
        secondary: '#091849',
      },
      dark: {
        primary: '#8F20EF',
        secondary: '#091849',
      },
    },
  },
  icons: {
    values: {
      AnalyticsTemplate: {
        component: AnalyticsTemplate,
      },
      URLProfiler: {
        component: URLProfiler,
      },
      PayPal: {
        component: PayPal,
      },
      btcsmall: {
        component: btcsmall,
      },
      cardsmall: {
        component: cardsmall,
      },
      radioOn: {
        component: RadioOn,
      },
      radioOff: {
        component: RadioOff,
      },
      dashboardOpen: {
        component: dashboardOpen,
      },
      dashboardClose: {
        component: dashboardClose,
      },
      user: {
        component: user,
      },
      company: {
        component: company,
      },
      itemDollar: {
        component: itemDollar,
      },
      itemTasks: {
        component: itemTasks,
      },
      itemFolders: {
        component: itemFolders,
      },
      Ga: {
        component: Ga,
      },
      UploadLink: {
        component: UploadLink,
      },
      Usd: {
        component: Usd,
      },
      CardRus: {
        component: CardRus,
      },
      Bitcoin: {
        component: Bitcoin,
      },
      Eth: {
        component: Eth,
      },
      CardUa: {
        component: CardUa,
      },
      Fiverr: {
        component: Fiverr,
      },
      WirePay: {
        component: WirePay,
      },
      ProjectIcon: {
        component: ProjectIcon,
      },
      GroupIcon: {
        component: GroupIcon,
      },
      ProjectGroupIcon: {
        component: ProjectGroupIcon,
      },
      OrderItemIcon: {
        component: OrderItemIcon,
      },
      summary: {
        component: Summary,
      },
      tier: {
        component: Tier,
      },
      economics: {
        component: Economics,
      },
      crossmap: {
        component: Crossmap,
      },
      contentAudit: {
        component: ContentAudit,
      },
      budgets: {
        component: Budgets,
      },
      backlink: {
        component: Backlink,
      },
      backlinkDeleted: {
        component: BacklinkDeleted,
      },
      gmail: {
        component: gmail,
      },
      logout: {
        component: logout,
      },
      phone: {
        component: phone,
      },
      telegram: {
        component: telegram,
      },
      menuContentMarketing: {
        component: menuContentMarketing,
      },
      menuAffiliateMarketing: {
        component: menuAffiliateMarketing,
      },
      menuInsights: {
        component: menuInsights,
      },
      menuFinance: {
        component: menuFinance,
      },
      menuLinkBuilding: {
        component: menuLinkBuilding,
      },
      menuMaintenance: {
        component: menuMaintenance,
      },
      menuMyDashboard: {
        component: menuMyDashboard,
      },
      menuReports: {
        component: menuReports,
      },
      menuSemantics: {
        component: menuSemantics,
      },
      menuTeam: {
        component: menuTeam,
      },
      menuTools: {
        component: menuTools,
      },
      notification: {
        component: notification,
      },
      logo: {
        component: Logo,
      },
      searchIcon: {
        component: searchIcon,
      },
      searchDark: {
        component: searchDark,
      },
      overview: {
        component: overview,
      },
      backlinks: {
        component: backlinks,
      },
      newContent: {
        component: newContent,
      },
      updatedContent: {
        component: updatedContent,
      },
      moveUp: {
        component: moveUp,
      },
      moveDown: {
        component: moveDown,
      },
      chevronDown: {
        component: chevronDown,
      },
      chevronLeft: {
        component: chevronLeft,
      },
      chevronRight: {
        component: chevronRight,
      },
      burger: {
        component: burger,
      },
      close: {
        component: close,
      },
      plus: {
        component: Plus,
      },
      minus: {
        component: Minus,
      },
      Delete: {
        component: Delete,
      },
      DeleteDocument: {
        component: DeleteDocument,
      },
      edit: {
        component: edit,
      },
      addNew: {
        component: AddNew,
      },
      save: {
        component: save,
      },
      cloud: {
        component: cloud,
      },
      tableSort: {
        component: tableSort,
      },
      tableSortUp: {
        component: tableSortUp,
      },
      tableSortDown: {
        component: tableSortDown,
      },
      checkbox: {
        component: checkbox,
      },
      checkboxOn: {
        component: checkboxOn,
      },
      googleLogo: {
        component: googleLogo,
      },
      fiverrLogo: {
        component: fiverrLogo,
      },
      snackSuccess: {
        component: snackSuccess,
      },
      snackError: {
        component: snackError,
      },
      snackWarning: {
        component: snackWarning,
      },
      snackInfo: {
        component: snackInfo,
      },
      traffic: {
        component: traffic,
      },
      keywords: {
        component: keywords,
      },
      content: {
        component: content,
      },
      spending: {
        component: spending,
      },
      income: {
        component: income,
      },
      profit: {
        component: profit,
      },
      NEW_PAGE_TRANSLATE: {
        component: NEW_PAGE_TRANSLATE,
      },
      PAGE_META_CHANGED: {
        component: PAGE_META_CHANGED,
      },
      PAGE_CONTENT_CHANGED: {
        component: PAGE_CONTENT_CHANGED,
      },
      PAGE_CONTENT_PLANNED: {
        component: PAGE_CONTENT_PLANNED,
      },
      PAGE_CREATED: {
        component: PAGE_CREATED,
      },
      PAGE_DELETED: {
        component: PAGE_DELETED,
      },
      PAGE_REDIRECTED: {
        component: PAGE_REDIRECTED,
      },
      CONTENT_PROOF_READING: {
        component: CONTENT_PROOF_READING,
      },
      CONTENT_UPDATE_PROOF_READING: {
        component: CONTENT_PROOF_READING,
      },
      CONTENT_NEW_PAGE: {
        component: CONTENT_NEW_PAGE,
      },
      CONTENT_UPDATE: {
        component: CONTENT_UPDATE,
      },
      LINK_NEW: {
        component: LINK_NEW,
      },
      LINK_DELETED: {
        component: LINK_DELETED,
      },
      LINK_PLANNED: {
        component: LINK_PLANNED,
      },
      CopyIcon: {
        component: CopyIcon,
      },
      spamDownload: {
        component: spamDownload,
      },
      approved: {
        component: approved,
      },
      approvedUser: {
        component: approvedUser,
      },
      rejected: {
        component: rejected,
      },
      btnPlus: {
        component: btnPlus,
      },
      btnSend: {
        component: btnSend,
      },
      inprogress: {
        component: inprogress,
      },
      itemComplete: {
        component: itemComplete,
      },
      loggedUsers: {
        component: loggedUsers,
      },
      profilerOperations: {
        component: profilerOperations,
      },
      profilerPosition: {
        component: profilerPosition,
      },
      profilerMainKeyword: {
        component: profilerMainKeyword,
      },
      profilerKeywords: {
        component: profilerKeywords,
      },
      searchInputIcon: {
        component: searchInputIcon,
      },
      copywriters: {
        component: copywriters,
      },
      global: {
        component: global,
      },
      arrowLeft: {
        component: arrowLeft,
      },
      tooltip: {
        component: tooltip,
      },
      tooltipViolet: {
        component: tooltipViolet,
      },
      paymentDetails: {
        component: paymentDetails,
      },
      relatedTo: {
        component: relatedTo,
      },
      brefProjectStart: {
        component: brefProjectStart,
      },
      brefUrlStart: {
        component: brefUrlStart,
      },
      dots: {
        component: dots,
      },
      btnApply: {
        component: btnApply,
      },
      btnEdit: {
        component: btnEdit,
      },
      btnDelete: {
        component: btnDelete,
      },
      btnCancel: {
        component: btnCancel,
      },
      brokenLine: {
        component: brokenLine,
      },
      update: {
        component: update,
      },
      lbTarget: {
        component: lbTarget,
      },
      lbReferring: {
        component: lbReferring,
      },
      LBOBcard: {
        component: LBOBcard,
      },
      LBOBplanet: {
        component: LBOBplanet,
      },
      LBOBstack: {
        component: LBOBstack,
      },
      UploadData: {
        component: UploadData,
      },
      Upload: {
        component: Upload,
      },
      success: {
        component: success,
      },
      errorIcon: {
        component: errorIcon,
      },
      checkboxMinus: {
        component: checkboxMinus,
      },
      refresh: {
        component: refresh,
      },
      menuMarketingPlan: {
        component: menuMarketingPlan,
      },
      CPArticles: {
        component: CPArticles,
      },
      CPUpdate: {
        component: CPUpdate,
      },
      CPСopywriter: {
        component: CPСopywriter,
      },
      CPworks: {
        component: CPworks,
      },
      CPSpending: {
        component: CPSpending,
      },
      Capitalist: {
        component: Capitalist,
      },
      Card: {
        component: Card,
      },
      NewPayPal: {
        component: NewPayPal,
      },
      LBPgear: {
        component: LBPgear,
      },
      LBPtime: {
        component: LBPtime,
      },
      LBPanchor: {
        component: LBPanchor,
      },
      copyData: {
        component: copyData,
      },
      refreshData: {
        component: refreshData,
      },
      arrowShuffle: {
        component: arrowShuffle,
      },
      PBNGear: {
        component: PBNGear,
      },
      PBNStack: {
        component: PBNStack,
      },
      PBNTime: {
        component: PBNTime,
      },
      PBNWarning: {
        component: PBNWarning,
      },
      searchGreyIcon: {
        component: searchGreyIcon,
      },
      management: {
        component: management,
      },
      licenseLogo: {
        component: licenseLogo,
      },
      check: {
        component: check,
      },
      PBNPerformance: {
        component: PBNPerformance,
      },
      ProjectContent: {
        component: ProjectContent,
      },
      UsersActivity: {
        component: UsersActivity,
      },
      UsersPerformance: {
        component: UsersPerformance,
      },
      PassportReport: {
        component: PassportReport,
      },
      Conversions: {
        component: Conversions,
      },
      RlTop: {
        component: RlTop,
      },
      KeywordsReport: {
        component: KeywordsReport,
      },
      HeatmapRD: {
        component: HeatmapRD,
      },
      HeatmapURL: {
        component: HeatmapURL,
      },
      CPWarning: {
        component: CPWarning,
      },
    },
  },
});
