<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99967 5.38379V8.26712"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.7334 9.99967H14.6167"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.99967 11.7334V14.6167"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.38379 9.99967H8.26712"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'lbTarget',
};
</script>

<style scoped></style>
