<template>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.69 3.44916L18.0725 14.6192C18.8142 15.9175 17.8767 17.5333 16.3817 17.5333H3.61665C2.12082 17.5333 1.18332 15.9175 1.92582 14.6192L8.30832 3.44916C9.05582 2.13999 10.9425 2.13999 11.69 3.44916Z" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.7664 9.89949C12.743 10.8762 12.743 12.4587 11.7664 13.4353C10.7897 14.412 9.20721 14.412 8.23055 13.4353C7.25388 12.4587 7.25388 10.8762 8.23055 9.89949C9.20721 8.92283 10.7905 8.92283 11.7664 9.89949" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.9987 9.16683V8.3335" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.9987 14.167V15.0003" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.5 11.6667H13.3333" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.49935 11.6667H6.66602" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.7654 9.9L12.357 9.30833" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.23203 13.4333L7.64036 14.025" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.7654 13.4333L12.357 14.025" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.23203 9.9L7.64036 9.30833" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
  name: 'PBNWarning',
};
</script>

<style scoped></style>
