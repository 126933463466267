import { render, staticRenderFns } from "./PBNTime.vue?vue&type=template&id=746dba4b&scoped=true&"
import script from "./PBNTime.vue?vue&type=script&lang=js&"
export * from "./PBNTime.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "746dba4b",
  null
  
)

export default component.exports