<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.6435 25.5H8.3565C6.2265 25.5 4.5 23.7735 4.5 21.6435V8.3565C4.5 6.2265 6.2265 4.5 8.3565 4.5H27.642C29.7735 4.5 31.5 6.2265 31.5 8.3565V21.642C31.5 23.7735 29.7735 25.5 27.6435 25.5Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M21 25.5L21.75 31.5" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15 25.5L14.25 31.5" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.21 31.5H23.79" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18 10.5V9" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18 19.5V21" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M15.396 18.429C15.7845 19.065 16.4475 19.506 17.25 19.506H18H18.894C20.058 19.506 21 18.5625 21 17.4C21 16.434 20.343 15.5925 19.4055 15.357L16.593 14.652C15.657 14.415 15 13.572 15 12.606C15 11.4435 15.9435 10.5 17.106 10.5H18H18.75C19.5495 10.5 20.211 10.938 20.601 11.571"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Conversions',
};
</script>

<style scoped></style>
