<template>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="8" fill="#FFF8F2"/>
<path d="M17.4143 9.91904C18.1953 10.7001 18.1953 11.9664 17.4143 12.7475C16.6332 13.5285 15.3669 13.5285 14.5858 12.7475C13.8048 11.9664 13.8048 10.7001 14.5858 9.91904C15.3669 9.13799 16.6332 9.13799 17.4143 9.91904" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 22.6666V13.3333" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.3333 16H9.33331C9.33331 19.6817 12.3183 22.6667 16 22.6667C19.6816 22.6667 22.6666 19.6817 22.6666 16H20.6666" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: 'LBPanchor',
};
</script>

<style scoped>

</style>
