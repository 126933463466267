<template>
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.7959 8.45406C33.068 13.7261 33.068 22.2739 27.7959 27.5459C22.5238 32.818 13.9761 32.818 8.70406 27.5459C3.43198 22.2738 3.43198 13.7261 8.70406 8.45406C13.9761 3.18198 22.5239 3.18198 27.7959 8.45406"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.8075 22.9997H16.597C15.67 22.9997 14.917 22.2482 14.917 21.3197V18.0767H20.8075C22.156 18.0767 23.251 19.1702 23.251 20.5202V20.5577C23.2495 21.9062 22.156 22.9997 20.8075 22.9997V22.9997Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.215 18.0765H14.917V14.706C14.917 13.764 15.6805 13.0005 16.6225 13.0005H20.215C21.5845 13.0005 22.696 14.1105 22.696 15.4815V15.597C22.696 16.9665 21.586 18.0765 20.215 18.0765Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.5849 13.0049H13.2549"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.5999 22.9951H13.2549"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.0947 11.3398V13.0048"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 11.3398V13.0048"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 24.6601V22.9951"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.0947 24.6601V22.9951"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Bitcoin',
};
</script>

<style scoped></style>
