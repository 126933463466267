import { render, staticRenderFns } from "./menuContentMarketing.vue?vue&type=template&id=67d92542&scoped=true&"
import script from "./menuContentMarketing.vue?vue&type=script&lang=js&"
export * from "./menuContentMarketing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67d92542",
  null
  
)

export default component.exports