import { render, staticRenderFns } from "./approvedUser.vue?vue&type=template&id=2af7f0ca&scoped=true&"
import script from "./approvedUser.vue?vue&type=script&lang=js&"
export * from "./approvedUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2af7f0ca",
  null
  
)

export default component.exports