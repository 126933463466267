<template>
  <component :is="layout">
    <router-view />
  </component>
</template>
<script>
import EmptyLayout from '@/layouts/EmptyLayout';
import MainLayout from './layouts/MainLayout';
export default {
  name: 'App',
  components: {MainLayout, EmptyLayout},
  computed: {
    layout() {
      return this.$route.meta.layout || 'EmptyLayout';
    },
  },
  created() {
    fetch('https://opensheet.elk.sh/1vUQz4AE9M-Ftarzr92XJ5gSdE3QLhAZaYl08SxDo63Y/tooltip')
        .then((res) => res.json())
        .then((data) => {
          this.$store.commit('updateTooltips', data);
        });
  },
};
</script>
