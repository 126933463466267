import { render, staticRenderFns } from "./LINK_DELETED.vue?vue&type=template&id=54cee871&scoped=true&"
import script from "./LINK_DELETED.vue?vue&type=script&lang=js&"
export * from "./LINK_DELETED.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54cee871",
  null
  
)

export default component.exports