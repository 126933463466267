<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99967 15.8333V10"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.19434 11.6667L10.0002 9.86084L11.806 11.6667"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3329 15.8332H15.4662C17.0729 15.8332 18.3829 14.5232 18.3829 12.9165C18.3829 11.3098 17.0729 9.99984 15.4662 9.99984H15.1037V9.1665C15.1037 6.40817 12.862 4.1665 10.1037 4.1665C7.62288 4.1665 5.56621 5.9815 5.17621 8.35234C3.19538 8.45317 1.61621 10.0773 1.61621 12.0832C1.61621 14.154 3.29538 15.8332 5.36621 15.8332H6.66621"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'UploadLink',
};
</script>

<style scoped></style>
