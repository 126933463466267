<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7Z"
      fill="#F5F7FB"
    />
    <path
      d="M7.0005 6.50003V10.5"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="7.00098" cy="3.75003" r="0.75" fill="#8591AE" />
  </svg>
</template>

<script>
export default {
  name: 'tooltip',
};
</script>

<style scoped></style>
