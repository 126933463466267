<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="8" fill="#F3F9FF" />
    <path
      d="M12.6667 16.8333H19.3334"
      stroke="#1E96FC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6667 20.1667H19.3334"
      stroke="#1E96FC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 8.5L18.6433 8.5C19.085 8.5 19.5092 8.67583 19.8217 8.98833L22.1783 11.345C22.4908 11.6575 22.6667 12.0817 22.6667 12.5233V21.8333C22.6667 22.7542 21.9208 23.5 21 23.5H11C10.0792 23.5 9.33334 22.7542 9.33334 21.8333L9.33334 10.1667C9.33334 9.24583 10.0792 8.5 11 8.5Z"
      stroke="#1E96FC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.6667 12.6667H19.3333C18.8733 12.6667 18.5 12.2933 18.5 11.8333V8.5"
      stroke="#1E96FC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6667 13.5H15.1667"
      stroke="#1E96FC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'CPArticles',
};
</script>

<style scoped></style>
