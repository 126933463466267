<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.0949 5.69882C15.1374 7.74127 15.1374 11.0527 13.0949 13.0952C11.0525 15.1376 7.74102 15.1376 5.69858 13.0952C3.65614 11.0527 3.65614 7.74127 5.69858 5.69882C7.74102 3.65638 11.0525 3.65638 13.0949 5.69882" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.8332 15.8335L13.0916 13.0918" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: 'searchGreyIcon',
};
</script>

<style scoped>

</style>
