<template>
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.25 12H32.25"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.75 28.5H9.75C7.2645 28.5 5.25 26.4855 5.25 24V12C5.25 9.5145 7.2645 7.5 9.75 7.5H27.75C30.2355 7.5 32.25 9.5145 32.25 12V24C32.25 26.4855 30.2355 28.5 27.75 28.5Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.1667 24L19.8338 16.5L16.5 23.9989"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.3125 22.3336H22.2519"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.417 21.5C14.417 22.8805 13.2981 24 11.917 24C10.5359 24 9.41699 22.8805 9.41699 21.5"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.41634 21.5L9.41634 16.5"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.4163 21.5L14.4163 16.5"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'CardUa',
};
</script>

<style scoped></style>
