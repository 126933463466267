<template>
  <div>
    <v-icon v-if="multisort" size="20">{{ prop ? prop : '' }}</v-icon>
    <v-icon v-else size="20">
      {{
        options.sortBy[0] === value && !options.sortDesc[0]
          ? '$tableSortDown'
          : options.sortBy[0] === value && options.sortDesc[0]
          ? '$tableSortUp'
          : '$tableSort'
      }}
    </v-icon>
  </div>
</template>
<script>
export default {
  name: 'SortFIcon',
  props: ['options', 'value', 'multisort'],
  computed: {
    prop() {
      if (this.options?.sortBy.includes(this.value)) {
        if (this.options?.sortDesc[this.options.sortBy.indexOf(this.value)]) {
          return '$tableSortUp';
        } else return '$tableSortDown';
      } else return '$tableSort';
    },
  },
};
</script>
<style scoped></style>
