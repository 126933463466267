<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9461 9.05399C26.0176 6.98249 29.3746 6.98249 31.4461 9.05399V9.05399C33.5176 11.1255 33.5176 14.4825 31.4461 16.554L22.6276 25.3725C20.5561 27.444 17.1991 27.444 15.1276 25.3725V25.3725C13.0561 23.301 13.0561 19.944 15.1276 17.8725L16.4461 16.554"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0541 28.446C9.98261 30.5175 6.62561 30.5175 4.55411 28.446V28.446C2.48261 26.3745 2.48261 23.0175 4.55411 20.946L13.3726 12.1275C15.4441 10.056 18.8011 10.056 20.8726 12.1275V12.1275C22.9441 14.199 22.9441 17.556 20.8726 19.6275L19.5001 21"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.85476 12.645H2.99976"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6443 3V6.855"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.41528 5.41498L8.31028 8.30998"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.0393 23.25L32.8943 23.25"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.2498 32.895L23.2498 29.04"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.4788 30.48L27.5838 27.585"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'BacklinkDeleted',
};
</script>

<style scoped></style>
