<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.125 5.875L5.875 14.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.33325 5.83331L14.1249 5.87415L14.1666 11.6666"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'moveUp',
};
</script>

<style scoped></style>
