<template>
  <section @click.middle="closeSnackbar" @mouseenter="pauseTimeout" @mouseleave="resumeTimeout">
    <v-snackbars
      left
      light
      multi-line
      max-width="320"
      content-class="snackbar hidden"
      ref="snack"
      :messages.sync="msgs"
      :timeout="snackTimeout ? snackTimeout : this.$store.getters.getSnackbarTimeout">
      <template v-slot:default="{message}">
        <v-row class="fz-14 font-weight-medium text-black lh-5 ma-0 mb-2 align-center">
          <v-icon size="24" class="mr-2">${{ 'snack' + message.icon }}</v-icon>
          {{ message.title }}
        </v-row>
        <p class="ml-8">{{ message.text }}</p>
      </template>
      <template v-slot:action="{close}">
        <v-btn
          elevation="0"
          height="20"
          width="20"
          class="pa-0"
          style="border-radius: 6px; background: #f5f7fb; min-width: 20px"
          :ripple="false"
          @click="close()">
          <v-icon size="20">$close</v-icon>
        </v-btn>
      </template>
    </v-snackbars>
  </section>
</template>
<script>
import VSnackbars from 'v-snackbars';
export default {
  components: {'v-snackbars': VSnackbars},
  name: 'MdSnackbars',
  props: ['messages', 'snackTimeout'],
  computed: {
    msgs: {
      get() {
        return this.messages;
      },
      set(val) {
        this.$emit('update:messages', val);
      },
    },
  },
  methods: {
    closeSnackbar() {
      this.msgs = []; // Закрыть уведомление
      this.$refs.snack.snackbars = [];
    },
    pauseTimeout() {
      clearTimeout(this.resumeTimeoutId); // Отменяем таймер resumeTimeout, если он активен
      this.$refs.snack.snackbars = this.$refs?.snack?.snackbars?.map((snackbar) => {
        return {...snackbar, timeout: clearTimeout(snackbar.timeout), show: true};
      });
    },
    resumeTimeout() {
      this.$refs.snack.snackbars = this.$refs?.snack?.snackbars?.map((snackbar) => {
        return {
          ...snackbar,
          timeout: setTimeout(() => this.$refs.snack.removeMessage(snackbar.key, true), 2000),
        };
      });
      // Сохраняем идентификатор таймера setTimeout в переменной resumeTimeoutId
      this.resumeTimeoutId = setTimeout(() => {
        this.msgs = [];
        this.$refs.snack.snackbars = [];
      }, 2100);
    },
  },
};
</script>
<style scoped></style>
