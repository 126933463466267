import Vue from 'vue';
import Vuex from 'vuex';
import {monetizationModule} from '@/store/modules/monetizationModule';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    monetization: monetizationModule,
  },
  state: {
    allProjectsTech: [],
    orderTypes: [
      {
        name: '1. Submit Links',
        value: 'SUBMIT_LINKS',
        permissionName: 'readFinanceOrdersSubmitLinks',
        permissionValue: null,
      },
      {
        name: '2. Content Deploy',
        value: 'CONTENT_DEPLOY',
        permissionName: 'readFinanceOrdersContentDeploy',
        permissionValue: null,
      },
      {
        name: '3. Crowd Links',
        value: 'CROWD_LINKS',
        permissionName: 'readFinanceOrdersCrowdLinks',
        permissionValue: null,
      },
      {
        name: '4. Content',
        value: 'CONTENT',
        permissionName: 'readFinanceOrdersContent',
        permissionValue: null,
      },
      {
        name: '5. Design',
        value: 'DESIGN',
        permissionName: 'readFinanceOrdersDesign',
        permissionValue: null,
      },
      {
        name: '6. Development',
        value: 'DEVELOPMENT',
        permissionName: 'readFinanceOrdersDevelopment',
        permissionValue: null,
      },
      {
        name: '7. Docs',
        value: 'DOCS',
        permissionName: 'readFinanceOrdersDocs',
        permissionValue: null,
      },
      {
        name: '8. Domain',
        value: 'DOMAIN',
        permissionName: 'readFinanceOrdersDomain',
        permissionValue: null,
      },
      {
        name: '9. Hosting',
        value: 'HOSTING',
        permissionName: 'readFinanceOrdersHosting',
        permissionValue: null,
      },
      {
        name: '10. Images',
        value: 'IMAGES',
        permissionName: 'readFinanceOrdersImages',
        permissionValue: null,
      },
      {
        name: '11. Infrastructure',
        value: 'INFRASTRUCTURE',
        permissionName: 'readFinanceOrdersInfrastructure',
        permissionValue: null,
      },
      {
        name: '12. Tools',
        value: 'TOOLS',
        permissionName: 'readFinanceOrdersTools',
        permissionValue: null,
      },
      {
        name: '13. Outreach Links',
        value: 'OUTREACH_LINKS',
        permissionName: 'readFinanceOrdersOutreachLinks',
        permissionValue: null,
      },
      {
        name: '14. Media materials',
        value: 'MEDIA_MATERIALS',
        permissionName: 'readFinanceOrdersMediaMaterials',
        permissionValue: null,
      },
    ],
    paymentTypes: [
      {
        name: 'USDT TRC20',
        value: 'USDT_TRC20',
      },
      {
        name: 'USDT ERC20',
        value: 'USDT_ERC20',
      },
      {
        name: 'USDC ERC20',
        value: 'USDC_ERC20',
      },
      {
        name: 'Card RUS',
        value: 'CARD_RUS',
      },
      {
        name: 'Card UA',
        value: 'CARD_UA',
      },
      {
        name: 'BTC',
        value: 'BTC',
      },
      {
        name: 'ETH',
        value: 'ETH',
      },
      {
        name: 'Fiverr',
        value: 'FIVERR',
      },
      {
        name: 'PayPal',
        value: 'PAYPAL',
      },
    ],
    selectedTool: {
      selectedDomain: null,
      charts: null,
      allCountries: null,
      selectedCountry: null,
    },
    snackbarTimeout: 5000,
    me: JSON.parse(localStorage.getItem('userInfo')) || null,
    triggerMonitoringTimeout: 5000,
    permissions: null,
    tooltips: null,
    christmasMode: localStorage.getItem('md_christmas'),
    allProjects: [],
    allProjectsPbn: [],
    allRegions: [],
    allLang: null,
    allLanguages: [],
    allCountries: [],
    siteTypes: [],
    currentStage: [],
    projectStatuses: [],
    domainTypes: [],
    portalThemes: [],
    allUsers: [],
    domainPortal: null,
    sendToIndexOpen: false,
    speedtest: null,
    top3chart: true,
    speedresult: 0,
    checkIndexing: null,
    switchProject: false,
    chosenRegions: [],
    globalLastStat: {
      projectIds: [],
      path: '',
      category: '',
      projectDomains: [],
      pageUrls: [],
    },
    globalRedirectProject: [],
    globalRedirectInfo: [],
    globalLoadingRedirect: false,
    globalRedirectRegion: [],
  },
  mutations: {
    updateOrderTypes(state) {
      state.orderTypes.forEach((item) => {
        const permissionPath = item.permissionName.split('.');
        item.permissionValue = permissionPath.reduce((acc, el) => acc?.[el], state?.me?.tabPermissions);
      });
      state.orderTypes = state.orderTypes.filter((item) => item.permissionValue === true);
    },
    updateChosenRegions(state, chosenRegions) {
      state.chosenRegions = chosenRegions;
    },
    updateLoadingRedirect(state, globalLoadingRedirect) {
      state.globalLoadingRedirect = globalLoadingRedirect;
    },
    updateRedirectInfo(state, globalRedirectInfo) {
      state.globalRedirectInfo = [];
      if (state.chosenRegions.length && globalRedirectInfo.length) {
        state.globalRedirectInfo.push({redirectedFrom: state.chosenRegions.reduce((acc, region) => {
          return acc = acc.concat(globalRedirectInfo[0].redirectedFrom.filter((item) => item.region.id == region));
        }, [])});
      } else {
        state.globalRedirectInfo = globalRedirectInfo;
      }
    },
    updateRedirectProject(state, globalRedirectProject) {
      state.globalRedirectInfo = [];
      state.globalRedirectProject = [];
      typeof globalRedirectProject === 'number' ?
          state.globalRedirectProject = globalRedirectProject :
          state.globalRedirectProject = state.allProjects.find((e) => e.domain === globalRedirectProject)?.id;
    },
    updateRedirectRegion(state, globalRedirectRegion) {
      state.globalRedirectRegion = [];
      state.globalRedirectRegion = globalRedirectRegion;
    },
    updateGlobalProjectStat(state, globalProjectStat) {
      state.globalLastStat.projectIds = [];
      state.globalLastStat.projectIds = globalProjectStat;
    },
    updateGlobalPathStat(state, globalPathStat) {
      state.globalLastStat.path = '';
      state.globalLastStat.path = globalPathStat;
    },
    updateGlobalCategoryStat(state, globalCategoryStat) {
      state.globalLastStat.category = [];
      state.globalLastStat.category = globalCategoryStat;
    },
    updateGlobalProjectDomainsStat(state, globalProjectDomains) {
      state.globalLastStat.projectDomains = [];
      state.globalLastStat.projectDomains = globalProjectDomains;
    },
    updateGlobalPageUrlsStat(state, globalPageUrls) {
      state.globalLastStat.pageUrls = [];
      state.globalLastStat.pageUrls = globalPageUrls;
    },
    clearCheck(state) {
      clearTimeout(state.checkIndexing);
    },
    updateChristmasMode(state, christmasMode) {
      state.christmasMode = christmasMode;
    },
    updateSwitchProject(state, switchProject) {
      state.switchProject = switchProject;
    },
    updateCheckIndexing(state, checkIndexing) {
      state.checkIndexing = checkIndexing;
    },
    updateTop3Chart(state, top3chart) {
      state.top3chart = top3chart;
    },
    updatePermissions(state, permissions) {
      state.permissions = permissions;
    },
    updateSpeedResult(state, speedresult) {
      state.speedresult = speedresult;
    },
    updateSpeedTest(state, speedtest) {
      state.speedtest = speedtest;
    },
    updateSendToIndexOpen(state, sendToIndexOpen) {
      state.sendToIndexOpen = sendToIndexOpen;
    },
    updateDomainPortal(state, domainPortal) {
      state.domainPortal = domainPortal;
    },
    updateSelectedTool(state, selectedTool) {
      state.selectedTool = selectedTool;
    },
    updateMe(state, me) {
      state.me = me;
    },
    updateTooltips(state, tooltips) {
      state.tooltips = tooltips;
    },
    updateAllProjects(state, allProjects) {
      state.allProjects = allProjects;
    },
    updateAllProjectsPbn(state, allProjects) {
      state.allProjectsPbn = allProjects;
    },
    updateAllProjectsTech(state, allProjectsTech) {
      state.allProjectsTech = allProjectsTech;
    },
    updateAllRegions(state, allRegions) {
      state.allRegions = allRegions;
    },
    updateAllLanguages(state, allLanguages) {
      state.allLanguages = allLanguages;
    },
    updateAllCountries(state, allCountries) {
      state.allCountries = allCountries;
    },
    updateSiteTypes(state, siteTypes) {
      state.siteTypes = siteTypes;
    },
    updateCurrentStage(state, currentStage) {
      state.currentStage = currentStage;
    },
    updateProjectStatuses(state, projectStatuses) {
      state.projectStatuses = projectStatuses;
    },
    updateDomainTypes(state, domainTypes) {
      state.domainTypes = domainTypes;
    },
    updatePortalThemes(state, portalThemes) {
      state.portalThemes = portalThemes;
    },
    updateAllUsers(state, allUsers) {
      state.allUsers = allUsers;
    },
  },
  getters: {
    getGlobalLastStat(state) {
      return state.globalLastStat;
    },
    getAllProjectsTech(state) {
      return state.allProjectsTech;
    },
    getAllProjectsPbn(state) {
      return state.allProjectsTech;
    },
    getOrderTypes(state) {
      return state.orderTypes;
    },
    getPaymentTypes(state) {
      return state.paymentTypes;
    },
    getChristmasMode(state) {
      return state.christmasMode;
    },
    getSnackbarTimeout(state) {
      return state.snackbarTimeout;
    },
    getSwitchProject(state) {
      return state.switchProject;
    },
    getCheckIndexing(state) {
      return state.checkIndexing;
    },
    getTop3Chart(state) {
      return state.top3chart;
    },
    getPermissions(state) {
      return state.permissions;
    },
    getSpeedResult(state) {
      return state.speedresult;
    },
    getSpeedtest(state) {
      return state.speedtest;
    },
    getSendToIndexOpen(state) {
      return state.sendToIndexOpen;
    },
    getDomainPortal(state) {
      return state.domainPortal;
    },
    getSelectedTool(state) {
      const tool = state.selectedTool;
      if (tool.charts && tool.charts.trafficByCategory) {
        tool.charts.trafficByCategory.forEach((el) => {
          if (el.category) el.category.value = true;
        });
      }
      return tool;
    },
    getMe(state) {
      return state.me;
    },
    getTooltips(state) {
      return state.tooltips;
    },
    getAllProjects(state) {
      return state.allProjects;
    },
    getAllRegions(state) {
      return state.allRegions;
    },
    getAllLanguages(state) {
      return state.allLanguages;
    },
    getAllCountries(state) {
      return state.allCountries;
    },
    getSiteTypes(state) {
      return state.siteTypes;
    },
    getCurrentStage(state) {
      return state.currentStage;
    },
    getProjectStatuses(state) {
      return state.projectStatuses;
    },
    getDomainTypes(state) {
      return state.domainTypes;
    },
    getPortalThemes(state) {
      return state.portalThemes;
    },
    getAllUsers(state) {
      return state.allUsers;
    },
    getRedirectProjects(state) {
      return state.globalRedirectProject;
    },
    getRedirectRegions(state) {
      return state.globalRedirectRegion;
    },
    getRedirectInfo(state) {
      return state.globalRedirectInfo;
    },
    getLoadingRedirect(state) {
      return state.globalLoadingRedirect;
    },
  },
});
