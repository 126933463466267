<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6666 17.5H4.99998C4.07915 17.5 3.33331 16.7542 3.33331 15.8333V7.50001C3.33331 6.57918 4.07915 5.83334 4.99998 5.83334H11.6666C12.5875 5.83334 13.3333 6.57918 13.3333 7.50001V15.8333C13.3333 16.7542 12.5875 17.5 11.6666 17.5Z"
      stroke="#1E96FC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3333 14.1667H15C15.9208 14.1667 16.6666 13.4208 16.6666 12.5V4.16667C16.6666 3.24583 15.9208 2.5 15 2.5H8.33329C7.41246 2.5 6.66663 3.24583 6.66663 4.16667V5.83333"
      stroke="#1E96FC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66663 10H9.99996"
      stroke="#1E96FC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66663 13.3333H9.99996"
      stroke="#1E96FC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'itemTasks',
};
</script>

<style scoped></style>
