<template>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M11.1941 2.55615C14.74 3.15199 17.4441 6.22865 17.4441 9.94365C17.4441 14.0862 14.0866 17.4437 9.94414 17.4437C6.22914 17.4437 3.15247 14.7395 2.55664 11.1937" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2.5549 8.69419C2.63406 8.22335 2.75656 7.76919 2.91906 7.33252" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5.16323 4.16504C4.79906 4.46587 4.4649 4.80004 4.16406 5.16421" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.6937 2.55594C8.22286 2.63511 7.76786 2.75844 7.33203 2.92094" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.3252 5.8335V10.326H6.66602" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
  name: 'PBNStack',
};
</script>

<style scoped></style>
