<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.0833 17.5H5.91667C4.99583 17.5 4.25 16.7542 4.25 15.8333V4.16667C4.25 3.24583 4.99583 2.5 5.91667 2.5H15.0833C16.0042 2.5 16.75 3.24583 16.75 4.16667V15.8333C16.75 16.7542 16.0042 17.5 15.0833 17.5Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.1665 6.24992H13.8332"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.1665 8.74992H13.8332"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.1665 12.4999H13.8332"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.1665 14.9999H13.8332"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.41667 5.62492V4.99992"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.41667 9.37492V9.99992"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.3315 8.92833C7.49317 9.19333 7.76984 9.37667 8.104 9.37667H8.4165H8.789C9.274 9.37667 9.6665 8.98333 9.6665 8.49917C9.6665 8.09667 9.39234 7.74583 9.00234 7.6475L7.83067 7.35417C7.44067 7.25583 7.1665 6.905 7.1665 6.5025C7.1665 6.01833 7.55984 5.625 8.044 5.625H8.4165H8.729C9.06234 5.625 9.33817 5.8075 9.49984 6.07167"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'paymentDetails',
};
</script>

<style scoped></style>
