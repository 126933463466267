import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import {createProvider} from './vue-apollo';
import '@/assets/scss/variables.scss';
import VCalendar from 'v-calendar';
import {DatePicker} from 'element-ui';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import VueClipboard from 'vue-clipboard2';
import VueMask from 'v-mask';
import titleMixin from './helpers/titleMixin';
import '@babel/polyfill';
import frontErrorHandler from '@/helpers/frontErrorHandler';

Vue.config.errorHandler = (error, vm, info) => {
  const componentName = vm.$options.name || vm.$options._componentTag;
  return frontErrorHandler(error, vm, info, componentName);
};

window.onerror = (message, url, line, col, errorObj) => {
  return frontErrorHandler(message, Vue.prototype);
};

const baseApiUrl = process.env.VUE_APP_SERVER_URL;
const target = process.env.TARGET;
console.log(`Running in: ${target}. Backend: ${baseApiUrl}.`);

Vue.use(VueMask);

// configure language
locale.use(lang);

Vue.mixin(titleMixin);
Vue.config.productionTip = false;
Vue.use(VCalendar, {
  componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
});
Vue.use(VueClipboard);
Vue.component(DatePicker.name, DatePicker);

new Vue({
  apolloProvider: createProvider(),
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
