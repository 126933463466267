<template>
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3525 34H32.3125"
      stroke="#8591AE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.3525 26H32.3125"
      stroke="#8591AE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.333 18H25.403C24.735 18 24.109 17.666 23.739 17.11L20.927 12.892C20.555 12.334 19.931 12 19.263 12H10.333C8.12301 12 6.33301 13.79 6.33301 16V38C6.33301 40.21 8.12301 42 10.333 42H38.333C40.543 42 42.333 40.21 42.333 38V22C42.333 19.79 40.543 18 38.333 18Z"
      stroke="#8591AE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.333 12V10C10.333 7.79 12.123 6 14.333 6H34.333C36.543 6 38.333 7.79 38.333 10V18"
      stroke="#8591AE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ProjectGroupIcon',
};
</script>

<style scoped></style>
