<template>
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.7959 8.45406C33.068 13.7261 33.068 22.2739 27.7959 27.5459C22.5238 32.818 13.9761 32.818 8.70406 27.5459C3.43198 22.2738 3.43198 13.7261 8.70406 8.45406C13.9761 3.18198 22.5239 3.18198 27.7959 8.45406"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.474 26.082C5.773 23.823 4.75 21.0285 4.75 18C4.75 10.575 10.825 4.5 18.25 4.5"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="0"
    />
    <path
      d="M17.875 10.5V12.375"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.875 25.5V23.625"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.6055 15C21.508 13.536 20.3005 12.375 18.8125 12.375H16.7575C15.3025 12.375 14.125 13.554 14.125 15.0075C14.125 16.215 14.947 17.268 16.117 17.562L19.6315 18.444C20.803 18.738 21.6235 19.791 21.6235 20.9985C21.6235 22.4535 20.4445 23.631 18.991 23.631H16.936C15.445 23.631 14.2375 22.467 14.1415 21"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Usd',
};
</script>

<style scoped></style>
