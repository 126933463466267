<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66675 10.8334L13.3334 10.8334"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66675 14.1667L13.3334 14.1667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.00004 2.5L12.6434 2.5C13.085 2.5 13.5092 2.67583 13.8217 2.98833L16.1784 5.345C16.4909 5.6575 16.6667 6.08167 16.6667 6.52333V15.8333C16.6667 16.7542 15.9209 17.5 15 17.5H5.00004C4.07921 17.5 3.33337 16.7542 3.33337 15.8333L3.33337 4.16667C3.33337 3.24583 4.07921 2.5 5.00004 2.5Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.6667 6.66667L13.3333 6.66667C12.8733 6.66667 12.5 6.29333 12.5 5.83333V2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66675 7.49998H9.16675"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'menuContentMarketing',
};
</script>

<style scoped></style>
