import { render, staticRenderFns } from "./itemFolders.vue?vue&type=template&id=e7bac398&scoped=true&"
import script from "./itemFolders.vue?vue&type=script&lang=js&"
export * from "./itemFolders.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7bac398",
  null
  
)

export default component.exports