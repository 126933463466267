<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 12.75L3 14.25L1.5 12.75"
      stroke="#2A355A"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.5 11.25L21 9.75L22.5 11.25"
      stroke="#2A355A"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.371 6.845C17.744 4.522 15.052 3 12 3C7.029 3 3 7.029 3 12C3 12.694 3.086 13.366 3.235 14.015"
      stroke="#2A355A"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.62891 17.155C6.25591 19.478 8.94791 21 11.9999 21C16.9709 21 20.9999 16.971 20.9999 12C20.9999 11.306 20.9139 10.634 20.7649 9.985"
      stroke="#2A355A"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 10.653V15.111C15 15.602 14.602 16 14.111 16H9.889C9.398 16 9 15.602 9 15.111V8.889C9 8.398 9.398 8 9.889 8H12.401C12.642 8 12.872 8.098 13.039 8.27L14.749 10.034C14.91 10.201 15 10.422 15 10.653V10.653Z"
      stroke="#2A355A"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'refresh',
};
</script>

<style scoped></style>
