import { render, staticRenderFns } from "./moveUp.vue?vue&type=template&id=7a6ebd88&scoped=true&"
import script from "./moveUp.vue?vue&type=script&lang=js&"
export * from "./moveUp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a6ebd88",
  null
  
)

export default component.exports