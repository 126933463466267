<template>
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M37.4945 12.828L31.8385 7.172C31.0885 6.422 30.0705 6 29.0105 6H14.6665C12.4565 6 10.6665 7.79 10.6665 10V38C10.6665 40.21 12.4565 42 14.6665 42H34.6665C36.8765 42 38.6665 40.21 38.6665 38V15.656C38.6665 14.596 38.2445 13.578 37.4945 12.828V12.828Z"
      stroke="#8591AE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M38.6665 16H30.6665C29.5625 16 28.6665 15.104 28.6665 14V6"
      stroke="#8591AE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.6665 22.2402H23.1665"
      stroke="#8591AE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.6665 28H23.1665"
      stroke="#8591AE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.6665 33.7598H23.1665"
      stroke="#8591AE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.9165 22C16.7785 22 16.6665 22.112 16.6665 22.25C16.6665 22.388 16.7785 22.5 16.9165 22.5C17.0545 22.5 17.1665 22.388 17.1665 22.25C17.1665 22.112 17.0545 22 16.9165 22"
      stroke="#8591AE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.9165 27.75C16.7785 27.75 16.6665 27.862 16.6665 28C16.6665 28.138 16.7785 28.25 16.9165 28.25C17.0545 28.25 17.1665 28.138 17.1665 28C17.1665 27.862 17.0545 27.75 16.9165 27.75"
      stroke="#8591AE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.9165 33.5C16.7785 33.5 16.6665 33.612 16.6665 33.75C16.6665 33.888 16.7785 34 16.9165 34C17.0545 34 17.1665 33.888 17.1665 33.75C17.1665 33.612 17.0545 33.5 16.9165 33.5"
      stroke="#8591AE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ProjectIcon',
};
</script>

<style scoped></style>
